import './NewQuote.css';
import React, {useEffect, useState} from 'react';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Checkbox, Dialog, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import { v4 as uuid } from 'uuid';
import Switch from "@mui/material/Switch";
import PersonIcon from "@mui/icons-material/Person";
import CommentIcon from '@mui/icons-material/Comment';
import GroupsIcon from '@mui/icons-material/Groups';
import DescriptionIcon from '@mui/icons-material/Description';
import quoteRequest from "../../../api/quoteRequest";
import sendErrorEmail from "../../../api/sendErrorEmail";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {jwtDecode} from "jwt-decode";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import quoteApi from "../../../api/quoteData";
import useSettings from "../../../hooks/useSettings";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import apiFilters from "../../../api/filters";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {useMediaQuery} from "@mui/system";
import {useQuoteRequest} from "../../../hooks/useQuoteRequest";

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            full: 1100
        },
    }
});

const getMuSelStyles = (key, searchArr, theme) => ({
    fontWeight: searchArr.indexOf(key) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
});

function MultiSelect({name, values, setChange, value, disabled, err}) {
    const theme = useTheme();

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;

        if(value.includes('*') && value.length > 1) {
            setChange([])
        } else if(value.includes('*') && values.length) {
            const allEqup = values?.map(v=> v.name);
            allEqup.shift();
            console.log(" A EQ ", allEqup)
            setChange(allEqup)
        } else {
            setChange(value);
        }

    };



    return (<FormControl sx={{margin: '0', minWidth: '100%'}} size="small" error={err}>
        <Select
            id={`select-${name}`}
            multiple
            value={value}
            onChange={handleChange}
            disabled={disabled}
            renderValue={(value) => value?.toString()?.slice(0, 15) + ' ...'}
        >
            {values?.map((val) => {
                return (<MenuItem
                    key={val.id}
                    value={val.id}
                    style={getMuSelStyles(val.name, values, theme)}
                >
                    {val.name}
                </MenuItem>);
            })}
        </Select>
    </FormControl>);
}


function SingleSelect({name, values, setChange, value, disabled, err}) {
    const theme = useTheme();

    const handleChange = (event) => {
        setChange(event.target.value);
    };

    return (<FormControl
        sx={{margin: '0', minWidth: '100%'}}
        size="small"
        error={err}
    >
        <Select
            id={`select-${name}`}
            value={value}
            onChange={handleChange}
            disabled={disabled}
        >
            {values.map((val) => {
                return (<MenuItem
                    key={val.id}
                    value={val.id}
                    style={getMuSelStyles(val.name, values, theme)}
                >
                    {val.name}
                </MenuItem>);
            })}
        </Select>
    </FormControl>);
}


// const equipData = [{
//     'name': 'SELECT ALL', 'id': '*',
// }, {
//     'name': 'D20', 'id': 'D20',
// }, {
//     'name': 'D40', 'id': 'D40',
// }, {
//     'name': 'D45', 'id': 'D45',
// }, {
//     'name': 'R20', 'id': 'R20',
// }, {
//     'name': 'R40', 'id': 'R40',
// }, {
//     'name': 'Flat Rack 40', 'id': 'Flat Rack 40',
// }, {
//     'name': 'Flat Rack 45', 'id': 'Flat Rack 45',
// }
// ];

let tradeDirectionData = []

const pricingGroupEmails = [
    {
        id: "1pricinggroupalaska@matson.com",
        value: "1pricinggroupalaska@matson.com",
    },
    {
        id: "pricing_oakcorp@matson.com",
        value: "pricing_oakcorp@matson.com",
    }
];

const customerServiceEmails = [
    {
        id: "premium@matson.com",
        value: "Premium premium@matson.com",
    },
    {
        id: "reefers@matson.com",
        value: "Reefer reefers@matson.com",
    },
    {
        id: "fwdrs@matson.com",
        value: "Freight Forwarders fwdrs@matson.com",
    },
    {
        id: "construction@matson.com",
        value: "Construction construction@matson.com",
    },
    {
        id: "general@matson.com",
        value: "General general@matson.com",
    },
    {
        id: "conventional@matson.com",
        value: "Conventional conventional@matson.com",
    },
    {
        id: "intermodal_phx@matson.com",
        value: "Intermodal intermodal_phx@matson.com",
    },
    {
        id: "guam@matson.com",
        value: "Guam Phoenix guam@matson.com",
    },
    {
        id: "csgum@matson.com",
        value: "Guam Island csgum@matson.com",
    }
];

export default function QuoteRequest() {
    const {
        targetRecipient, setTargetRecipient,targetRecipientValue, setTargetRecipientValue, checkBoxList,
        setCheckBoxList,customerName, setCustomerName,showSpinner, setShowSpinner,address,
        setAddress,city, setCity,state, setState,zip, setZip,firstName, setFirstName,lastName,
        setLastName,email, setEmail,phone, setPhone,serviceType, setServiceType,hazardous,
        setHazardous,oversized, setOversized,newBus, setNewBus,tradelane, setTradelane,origin,
        setOrigin,destination, setDestination,equipmentType, setEquipmentType,commodity,
        setCommodity,hazardousUNNumber, setHazardousUNNumber,effectiveStart, setEffectiveStart,
        effectiveEnd, setEffectiveEnd,length, setLength,width, setWidth,height, setHeight,
        loadsMoved, setLoadsMoved,targetRate, setTargetRate,margin, setMargin,volume,
        setVolume,justification, setJustification,weight, setWeight,polOrigin, setpolOrigin,
        polDestination, setpolDestination,comments, setComments,emailGroups, setEmailGroups,
        topExpanded, setTopExpand,botExpanded, setBotExpand,paramsSaved, setParamsSaved,manualEmail,
        setManualEmail, validationError, setValidationError,warningDialogOpen, setWarningDialogOpen,
        recordID, setRecordID,mappings, setMappings,origins, setOrigins,destinations, setDestinations,
        urgentQuote, setUrgentQuote,urgentQuoteDate, setUrgentQuoteDate,dropAndPull, setDropAndPull,
        liveLoad, setLiveLoad,liveUnload, setLiveUnload,selectedEmails, setSelectedEmails, equipmentDataRaw,
        setEquipmentDataRaw, equipData, setEquipData, setTradelaneDataRaw, tradelaneDataRaw
    } = useQuoteRequest();

    const sendErrorAlert = (e, requestedBy, requestEmail, id) => {
        console.log(" E >>>>>>>>>>>>>>  ", e)
        setShowSpinner(false);
        let code, message, type;
        if(e?.response?.data?.Error){
            const {Code, Message, Type} = e?.response?.data?.Error;
            code = Code;
            message = Message;
            type = Type;
        }

        if(e?.code) {
            code = e?.code;
            type = e?.type;
            message = e?.message;
        }


        const postObj =
            {
                data:
                        {
                            emailTo:  "metzel@matson.com",
                            requestedBy,
                            requestEmail,
                            errorCode: `${code}: ${type}`,
                            errorMessage: message,
                            subject: "Quote Request Failure Alert",
                            type: "quoteRequestEmailFail"
                        }
                    };
        if(id) postObj.data.quoteId = id;
        sendErrorEmail.post(postObj).then(a=>{
            setShowSpinner(false);
            dispatch({type: 'triggeredAlert', data: {
                    statusType: 'warning',
                    error: '',
                    messageTitle: 'Quote Request Send Error',
                    message: "We're sorry. There was an issue sending your quote.  Please try again.  If the problem continues, please reach out to the Cost Engine support team at costenginesupport@matson.com."
                }})
        });
    }

    const handleCheckboxChange = (event) => {
        const checkedId = event.target.value;

        if(event.target.checked){
            setSelectedEmails(oldArray => [...oldArray, checkedId])
        }else{
            setSelectedEmails(selectedEmails.filter(id=>id !== checkedId))
        }

    }

    const fetchFilterData = () => {
        setShowSpinner(true)
        apiFilters.get().then(
            (data) => {
                tradeDirectionData = [];
                for(let i in data.tradeLanesForQuoteRequest) {
                    tradeDirectionData.push({
                        id:  `${data.tradeLanesForQuoteRequest[i].TradeCategory.toUpperCase()} - ${data.tradeLanesForQuoteRequest[i].TradeLaneTitle.toUpperCase()}`,
                        name:  `${data.tradeLanesForQuoteRequest[i].TradeCategory.toUpperCase()} - ${data.tradeLanesForQuoteRequest[i].TradeLaneTitle.toUpperCase()}`
                    })
                }
                setTradelaneDataRaw(data.tradeLanesForQuoteRequest);
                setEquipmentDataRaw(data.equipment);
                setMappings(data.tradeMapping);
                setShowSpinner(false)
            }
        )};

    const updateOriginsAndDestinations = () => {
        setOrigin('');
        setDestination('');

        const otherObj = {id: 'OTHER', name: 'OTHER'};

        if(tradelane){

            let result = mappings.filter((v, i, a) => {
               if(v.originPortCode) return a.findIndex(t => (t.originPortCode === v.originPortCode)) === i
            })
                .map(mm=>{
                    if(mm.originPortCode && mm.originName){
                        return {
                            id: `${mm.originPortCode.toUpperCase()} - ${mm.originName.toUpperCase()}`,
                            name: `${mm.originPortCode.toUpperCase()} - ${mm.originName.toUpperCase()}`
                        }
                    }
                });
            let sortedList = result.sort((a, b) => a.name.localeCompare(b.name));
            setOrigins(sortedList);

            result = mappings.filter((v, i, a) => {
                if(v.destinationPortCode) return a.findIndex(t => (t.destinationPortCode === v.destinationPortCode)) === i
            })
                .map(mm=>{
                    if(mm.destinationPortCode && mm.destinationPortCode){
                        return {
                            id: `${mm.destinationPortCode.toUpperCase()} - ${mm.destinationName.toUpperCase()}`,
                            name: `${mm.destinationPortCode.toUpperCase()} - ${mm.destinationName.toUpperCase()}`
                        }
                    }
                });
            sortedList = result.sort((a, b) => a.name.localeCompare(b.name));
            setDestinations(sortedList);
        }


    }

    const { dispatch } = useSettings();

    const {user} = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);

    const dasToken = user?.signInUserSession?.idToken?.jwtToken;
    const decoded = dasToken ? jwtDecode(dasToken) : undefined;
    const requestedBy = decoded?.given_name + ' ' + decoded?.family_name;
    const requestEmail  = decoded?.email
    let recordEYED = '';

    const validateRequired = () => {
        let validErr = false;
        if(!customerName || !tradelane || !origin || !destination || !commodity || !equipmentType) {
            validErr = true;
            setWarningDialogOpen(true);
        }

        if(
            (serviceType === 'CY-DOOR' && !polDestination) ||
            (serviceType === 'DOOR-CY' && !polOrigin) ||
            (serviceType === 'DOOR-DOOR' && (!polDestination || !polOrigin))
        ) {
            validErr = true;
            setWarningDialogOpen(true);
        }

        if(newBus && !loadsMoved) {
            validErr = true;
            setWarningDialogOpen(true);
        }

        if(targetRecipientValue === 'pricing group') {
            if (!targetRate  || !volume || !justification) {
                validErr = true;
                setWarningDialogOpen(true);
            }
        } else {
            if(!email || !phone) {
                validErr = true;
                setWarningDialogOpen(true);
            }
        }

        setValidationError(validErr);
        if(!validErr) handleQuoteParamsSave();
    }

    const handleQuoteParamsSave = () => {
        setParamsSaved(true);
    }

    const handleQuoteParamsSubmit = () => {
        setShowSpinner(true);
        const quoteData = {
            tradelane, direction: '', shipDate: '', startDate: effectiveStart, endDate: effectiveEnd, createdBy: '', comments: '', requestedBy, requestEmail,
        };

        quoteApi.post({postType: 'quoteData',  data: quoteData}).then((d) => {
            if(!d?.results?.insertId) {
                const sendObj = {
                    response: {
                        data: {
                            Error: {
                                Code: 'Initial Quote Request Failed', Message: 'Missing Form Data', Type: 'User Input'
                            }
                        }
                    }
                }
                sendErrorAlert( sendObj,requestedBy, requestEmail, recordEYED || '');
            } else {
            recordEYED = d?.results?.insertId;
            setRecordID(recordEYED);
            const statusData = {
                status: 'request',
                recordID: recordEYED
            };

            quoteApi.post({postType: 'setStatus', data: statusData}).then((d) => {
                handleCustomerSubmit();
                }).catch(e => {
                    sendErrorAlert(e, requestedBy, requestEmail, recordEYED || '');
            });
            }

        }).catch(e => {
            sendErrorAlert(e, requestedBy, requestEmail, recordEYED || '');
        });
    };

    const handleRecipientChange = (checked) => {
        setTargetRecipient(checked);
        setTargetRecipientValue(checked ? 'customer service' : 'pricing group');
    }

    const handleCustomerSubmit = () => {
        const customerData = {
            recordID: recordEYED, customerName, address, city, state, zip, firstName, lastName, email, phone,
        };

        quoteApi.post({postType: 'customerData', data: customerData}).then((d) => {
            handleShipmentSubmit();
        }).catch((err) => {
            sendErrorAlert(err, requestedBy, requestEmail, recordEYED || '');
        });
    };

    const handleShipmentSubmit = () => {
        const shipmentData = {
            recordID: recordEYED,
            loadingLocation: origin,
            receiptPort: destination,
            dischargePort: '',
            deliveryLocation: '',
            commodity,
            tarrif: '',
            hazardousUnNumber: hazardousUNNumber,
            quantity: '',
            weight,
            margin,
            volume,
            oversized,
            length,
            width,
            height,
            equipType: equipmentType,
        };

        quoteApi.post({postType: 'shipmentData', data: shipmentData}).then((d) => {
            handleQuoteRequestSubmit();
        }).catch((err) => {
            sendErrorAlert(err, requestedBy, requestEmail, recordEYED || '');
        });
    };

    const resetForm = () => {
        setTargetRecipientValue('pricing group');
        setTargetRecipient(false);
        setCustomerName('');
        setAddress('');
        setCity('');
        setState('');
        setZip('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setServiceType('CY-CY');
        setHazardous(false);
        setVolume('');
        setOversized(false);
        setNewBus(false);
        setTradelane(false);
        setMargin('');
        setOrigin(false);
        setDestination(false);
        setEquipmentType([]);
        setCommodity('');
        setHazardousUNNumber('');
        setEffectiveStart(false);
        setEffectiveEnd(false);
        setLength('');
        setWidth('');
        setHeight('');
        setLoadsMoved('');
        setTargetRate('');
        setJustification('');
        setWeight('');
        setpolOrigin('');
        setpolDestination('');
        setComments('');
        setRecordID('');
        setParamsSaved(false);
        setUrgentQuote(false);
        setUrgentQuoteDate(null);
        setDropAndPull(false);
        setLiveLoad(false);
        setLiveUnload(false);
        setSelectedEmails([]);
    }

    const handleQuoteRequestSubmit = () => {
        let emailArr = selectedEmails;

        if(manualEmail && manualEmail.indexOf(',') > -1) {
            // split into array
            let tempArr = manualEmail.split(',');
            for(let i in tempArr) {
                if(tempArr[i]) {
                    let aa = tempArr[i].split('@');
                    if(aa[1] && aa[1].trim() == 'matson.com') emailArr.push(tempArr[i].trim().toLowerCase())
                }
            }
        }

        if(manualEmail && manualEmail.indexOf(',') == -1) {
            // split into array
            let aa = manualEmail.split('@');

            if(aa[1] && aa[1].trim() === 'matson.com') {
                emailArr.push(manualEmail.trim().toLowerCase())
            }

        }



        const quoteData = {
            recordID: recordEYED,
            emailArr,
            requestedBy, requestEmail,
            customerName, address, city, state, zip, firstName, lastName, email, phone,
            serviceType,
            hazardous,
            oversized,
            newBus,
            tradelane,
            volume,
            origin,
            destination,
            equipmentType,
            margin,
            commodity,
            hazardousUNNumber,
            length,
            width,
            height,
            loadsMoved,
            targetRate,
            justification,
            weight,
            polOrigin,
            polDestination,
            effectiveEnd,
            effectiveStart,
            comments,
            urgentQuote,
            urgentQuoteDate,
            dropAndPull,
            liveLoad,
            liveUnload
        };

        quoteApi.post({postType: 'quoteRequest', data: quoteData})
            .catch((err) => {
                sendErrorAlert(err, requestedBy, requestEmail, recordEYED || '');
        });

        quoteRequest.post({ MessageBody: quoteData, MessageDeduplicationId: uuid(), MessageGroupId: uuid()}).then((d) => {
            setShowSpinner(false);
            dispatch({type: 'verificationAlert', data: {
                    statusType: 'success',
                    messageTitle: 'Quote Request',
                    message: 'Your Quote Request has been sent.'
                }})
            resetForm();
        }).catch((err) => {
            sendErrorAlert(err, requestedBy, requestEmail, recordEYED || '');
        });
    };

    const contactName = firstName && lastName ? `${firstName} ${lastName}` : 'n/a';
    const contactAddress = address && city && state? <><div>{address} {city},</div><div>{state} {zip}</div></> : 'n/a';
    const lwh = length && width && height ? `${length}/${width}/${height}` : 'n/a';
    const tpov = effectiveStart && effectiveEnd ? `${dayjs(effectiveStart).format('MM/DD/YYYY')} through ${dayjs(effectiveEnd).format('MM/DD/YYYY')}` : 'n/a';

    const isMobile = useMediaQuery(theme.breakpoints.down('full'), {
        defaultMatches: true
    });

    const updateEquipmentTypes = () => {
        const equipData = [];
        //console.log(tradelaneDataRaw)
        //console.log(tradelane)

        // let foundTL = [];
        // if(tradelane) foundTL = tradelaneDataRaw.filter(tl => tradelane?.indexOf(tl.TradeLaneTitle) > -1);
        //
        // //console.log("FTL ", foundTL)
        // if(foundTL.length){
        //     let usingTradelane = foundTL[0]?.ID == 1 || foundTL[0]?.ID == 6 ? foundTL[0]?.ID : 1;
        //     equipmentDataRaw.forEach(eq => {
        //         let tradeArray=[];
        //         if (eq.QRTradelaneID?.indexOf(',') > -1) tradeArray = eq.QRTradelaneID.split(',');
        //         if (tradeArray.includes(usingTradelane.toString()) || eq.QRTradelaneID == usingTradelane) equipData.push({id: eq.name, name: eq.name})
        //     });
        //
        //     setEquipmentType([]);
        //     setEquipData([{name: 'SELECT ALL', id: '*'}, ...equipData]);
        // }

        console.log(" WELCOME TO QUOTE REQUEST !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")

        const eqs = [
            {
                "name": "Dry-20",
                "id": 1,
                "ID": 1,
                "EquipmentName": "Dry-20",
                "CTTradelaneID": "1,6",
                "QRTradelaneID": "1,6",
                "WorkTagID": "D20"
            },
            {
                "name": "Dry-20H",
                "id": 14,
                "ID": 14,
                "EquipmentName": "Dry-20H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Dry-40",
                "id": 2,
                "ID": 2,
                "EquipmentName": "Dry-40",
                "CTTradelaneID": "1,6",
                "QRTradelaneID": "1,6",
                "WorkTagID": "D40"
            },
            {
                "name": "Dry-40H",
                "id": 16,
                "ID": 16,
                "EquipmentName": "Dry-40H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Dry-45",
                "id": 3,
                "ID": 3,
                "EquipmentName": "Dry-45",
                "CTTradelaneID": "1,6",
                "QRTradelaneID": "1,6",
                "WorkTagID": "D45"
            },
            {
                "name": "Dry-45H",
                "id": 18,
                "ID": 18,
                "EquipmentName": "Dry-45H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Flatrack-20",
                "id": 19,
                "ID": 19,
                "EquipmentName": "Flatrack-20",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": "F20"
            },
            {
                "name": "Flatrack-20H",
                "id": 20,
                "ID": 20,
                "EquipmentName": "Flatrack-20H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Flatrack-40",
                "id": 21,
                "ID": 21,
                "EquipmentName": "Flatrack-40",
                "CTTradelaneID": "6",
                "QRTradelaneID": "1",
                "WorkTagID": "F40"
            },
            {
                "name": "Flatrack-40H",
                "id": 22,
                "ID": 22,
                "EquipmentName": "Flatrack-40H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Flatrack-45",
                "id": 23,
                "ID": 23,
                "EquipmentName": "Flatrack-45",
                "CTTradelaneID": "",
                "QRTradelaneID": "1",
                "WorkTagID": "F45"
            },
            {
                "name": "Flatrack-45H",
                "id": 24,
                "ID": 24,
                "EquipmentName": "Flatrack-45H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Insul-40-KFF",
                "id": 44,
                "ID": 44,
                "EquipmentName": "Insul-40-KFF",
                "CTTradelaneID": "6",
                "QRTradelaneID": "6",
                "WorkTagID": "I40"
            },
            {
                "name": "Insul-40H",
                "id": 25,
                "ID": 25,
                "EquipmentName": "Insul-40H",
                "CTTradelaneID": "",
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Insul-45H",
                "id": 26,
                "ID": 26,
                "EquipmentName": "Insul-45H",
                "CTTradelaneID": "",
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Insul-45H-KFF",
                "id": 45,
                "ID": 45,
                "EquipmentName": "Insul-45H-KFF",
                "CTTradelaneID": "6",
                "QRTradelaneID": "6",
                "WorkTagID": "I45"
            },
            {
                "name": "Opentop-20",
                "id": 28,
                "ID": 28,
                "EquipmentName": "Opentop-20",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Opentop-20H",
                "id": 29,
                "ID": 29,
                "EquipmentName": "Opentop-20H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Opentop-40",
                "id": 30,
                "ID": 30,
                "EquipmentName": "Opentop-40",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Opentop-40H",
                "id": 31,
                "ID": 31,
                "EquipmentName": "Opentop-40H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Opentop-45",
                "id": 32,
                "ID": 32,
                "EquipmentName": "Opentop-45",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Reefer-20",
                "id": 4,
                "ID": 4,
                "EquipmentName": "Reefer-20",
                "CTTradelaneID": "1",
                "QRTradelaneID": "1",
                "WorkTagID": "R20"
            },
            {
                "name": "Reefer-40",
                "id": 5,
                "ID": 5,
                "EquipmentName": "Reefer-40",
                "CTTradelaneID": "1",
                "QRTradelaneID": "1",
                "WorkTagID": "R40"
            },
            {
                "name": "Reefer-40-KFF",
                "id": 40,
                "ID": 40,
                "EquipmentName": "Reefer-40-KFF",
                "CTTradelaneID": "",
                "QRTradelaneID": "6",
                "WorkTagID": "R40KFF"
            },
            {
                "name": "Reefer-40-Temperature",
                "id": 39,
                "ID": 39,
                "EquipmentName": "Reefer-40-Temperature",
                "CTTradelaneID": "6",
                "QRTradelaneID": "6",
                "WorkTagID": "R40T"
            },
            {
                "name": "Reefer-40H",
                "id": 41,
                "ID": 41,
                "EquipmentName": "Reefer-40H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Reefer-45",
                "id": 42,
                "ID": 42,
                "EquipmentName": "Reefer-45",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Reefer-45H",
                "id": 43,
                "ID": 43,
                "EquipmentName": "Reefer-45H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Tank-20",
                "id": 33,
                "ID": 33,
                "EquipmentName": "Tank-20",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Tank-40",
                "id": 34,
                "ID": 34,
                "EquipmentName": "Tank-40",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Tank-40H",
                "id": 35,
                "ID": 35,
                "EquipmentName": "Tank-40H",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            },
            {
                "name": "Tank-45",
                "id": 36,
                "ID": 36,
                "EquipmentName": "Tank-45",
                "CTTradelaneID": null,
                "QRTradelaneID": null,
                "WorkTagID": null
            }
        ];

        eqs.forEach(eq => {
            equipData.push({id: eq.name, name: eq.name});
        });

        setEquipData([{name: 'SELECT ALL', id: '*'}, ...equipData]);
    }

    useEffect(() => {
        fetchFilterData();
    }, []);

    useEffect(() => {
        updateOriginsAndDestinations();
        updateEquipmentTypes();
    }, [tradelane]);

    useEffect(() => {
        setCheckBoxList(targetRecipient ? customerServiceEmails : pricingGroupEmails);
    }, [targetRecipient]);

    useEffect(() => {
        if(targetRecipientValue === 'customer service') {
            setTargetRate('');
            setMargin('');
            setJustification('');
        };
    }, [targetRecipientValue]);
//console.log(equipmentType)

    //console.log(" QUOTE GEN VER 2.0")

    if(showSpinner) {
        return (<Box sx={{position: 'absolute', left: '50%'}}>
            <CircularProgress/>
        </Box>)
    } else {

        return (
            <ThemeProvider theme={theme}  >
                <>
                    <Grid container>
                        {
                            !paramsSaved &&
                            <>
                                <Grid item mobile={12}>
                                    <Card
                                        sx={{
                                            border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                                        }}
                                    >
                                        <div className='quote-form-header-area' style={{color: '#fff'}}>
                                            <Grid container>
                                                <Grid item mobile={6}
                                                      style={{display: 'inline-flex', alignItems: 'center'}}><PersonIcon
                                                    sx={{margin: '-5px 13px 0 0'}}/> Quote Setup</Grid>
                                                <Grid item mobile={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider sx={{backgroundColor: '#ccc'}}/>
                                        <CardContent sx={{padding: '0px !important', marginTop: '50px', backgroundColor: '#fff'}}>
                                            <Grid container spacing={2} className='form-container'>
                                                <Grid
                                                    style={{ alignSelf: 'flex-start' }}
                                                    item mobile={12} tablet={6} full={6} className='form-label'
                                                >
                                                    Target Recipients *
                                                </Grid>
                                                <Grid item mobile={12} tablet={6} full={6} onClick={() => handleRecipientChange(!targetRecipient) }
                                                      style={{marginTop: '15px',cursor: 'pointer'}}>
                                                    <FormGroup>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <span>pricing group</span>
                                                            <Switch checked={targetRecipient}/>
                                                            <span>customer service</span>
                                                        </Stack>
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardContent sx={{padding: '0px !important', marginTop: '20px', backgroundColor: '#fff'}}>
                                            <Grid container spacing={2} className='form-container' >
                                                <Grid
                                                    item
                                                    style={{ alignSelf: 'flex-start' }}
                                                    mobile={12} tablet={6} full={6} className='form-label'>
                                                    Recipient Email Groups
                                                </Grid>
                                                <Grid item mobile={12} tablet={6} full={6}
                                                      style={{marginTop: '15px',cursor: 'pointer', marginLeft: '-27px'}}>
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '20px'}}>
                                                        {checkBoxList.map((item, index) => {
                                                            return (
                                                                <div style={{padding: "10px 20px"}} key={index}>
                                                                    <label style={{cursor: 'pointer'}}>
                                                                        <input
                                                                            type="checkbox"
                                                                            value={item.id}
                                                                            checked={selectedEmails.includes(item.id)}
                                                                            onChange={(event) => handleCheckboxChange(event)}
                                                                        />
                                                                        <span style={{paddingLeft: '7px'}}>{isMobile ? item.value.split('@')[0] : item.value}</span>
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </Box>
                                                </Grid>
                                                <Grid item mobile={12} tablet={6} full={6} className='form-label'
                                                      style={{ alignSelf: 'flex-start' }}
                                                >
                                                    Manual Recipient Email Entry
                                                </Grid>
                                                <Grid item mobile={12} tablet={6} full={6}
                                                      style={{marginTop: '15px',cursor: 'pointer', marginLeft: '-27px'}}>
                                                    <div style={{marginLeft: '20px'}}>For manual entry, enter multiple Matson.com email addresses only ( no client or external email addresses ) seperated by commas:</div><br/>
                                                    <div style={{marginLeft: '20px', width: '100%'}}>
                                                        <TextField
                                                            size="small"
                                                            style={{textAlign: 'left', width: isMobile? '90%' : '700px'}}
                                                            rows={2}
                                                            multiline={true}
                                                            value={manualEmail}
                                                            onChange={(event) => setManualEmail(event.target.value)}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>

                                        <CardContent sx={{padding: '0px !important', margin: '0'}}>
                                            <Divider sx={{backgroundColor: '#ccc'}}/>
                                        </CardContent>
                                        <CardContent sx={{padding: '20px 0 !important', marginTop: '40px', backgroundColor: '#fff'}}>
                                            <Grid container spacing={2} className='form-container'>
                                                <Grid item mobile={12} tablet={12} full={4} className='form-label wrap'>
                                                    <FormControl
                                                        component="fieldset"
                                                        variant="standard"
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox checked={urgentQuote} onChange={(e) => setUrgentQuote(e.target.checked)} name="" sx={{marginRight: '10px'}} />
                                                                }
                                                                label={<span style={{fontWeight: 'bold', whiteSpace: 'normal'}}>Urgent Quote (only use if quote request needs immediate attention)</span>}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                                {!isMobile && <Grid item full={2}></Grid> }
                                                <Grid item mobile={12} tablet={6} full={3} sx={{marginTop: isMobile ? '20px!important' : '10px!important'}}>
                                                    {
                                                        urgentQuote &&
                                                        <div style={{ fontWeight: 'bold'}}>Need quote returned to sales by:</div>
                                                    }
                                                </Grid>
                                                <Grid item mobile={12} tablet={6} full={3}>
                                                    {
                                                        urgentQuote &&
                                                        <DatePicker sx={{minWidth: '100%'}}
                                                                    onChange={setUrgentQuoteDate}
                                                                    slotProps={{textField: {size: 'small'}}}/>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item mobile={12} sx={{marginTop: '40px'}}>
                                    <Card
                                        sx={{
                                            border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                                        }}
                                    >
                                        <div className='quote-form-header-area' style={{color: '#fff'}}>
                                            <Grid container>
                                                <Grid item mobile={6}
                                                      style={{display: 'inline-flex', alignItems: 'center'}}><PersonIcon
                                                    sx={{margin: '-5px 13px 0 0'}}/> Customer Information</Grid>
                                                <Grid item mobile={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider sx={{backgroundColor: '#ccc'}}/>
                                        <CardContent sx={{padding: '0px !important', marginTop: '50px', backgroundColor: '#fff'}}>
                                            <Grid container >
                                                <Grid item mobile={12} tablet={12} full={6}>
                                                    <Grid container spacing={1} className='form-container'>
                                                        <Grid item mobile={12} tablet={6}
                                                              className={ `form-label ${(validationError && !customerName) ? 'form-label-err' : ''}`}
                                                        >
                                                            Account name *
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6}>
                                                            <TextField
                                                                id="outlined-basic"
                                                                size="small"
                                                                sx={{width: '100%', marginTop: '5px'}}
                                                                value={customerName}
                                                                error={validationError && !customerName}
                                                                onChange={(event) => setCustomerName(event.target.value)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item mobile={12} tablet={12} full={6} style={{marginTop: isMobile ? '-20px' : '-10px'}}>
                                                    <Grid container spacing={1} className='form-container'>
                                                        {
                                                            targetRecipientValue === 'customer service' &&
                                                            <>
                                                                <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !email) ? 'form-label-err' : ''}`}>
                                                                    Email *
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        sx={{width: '100%'}}
                                                                        value={email}
                                                                        error={validationError && !email}
                                                                        onChange={(event) => setEmail(event.target.value)}
                                                                    />
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !phone) ? 'form-label-err' : ''}`}>
                                                                    Phone *
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        sx={{width: '100%'}}
                                                                        value={phone}
                                                                        error={validationError && !phone}
                                                                        onChange={(event) => setPhone(event.target.value)}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardContent sx={{padding: '0px !important', margin: '20px 0'}}>
                                            <Divider sx={{backgroundColor: '#ccc'}}/>
                                        </CardContent>
                                        <CardContent sx={{padding: '0px !important', margin: '30px 0 0 0'}}>
                                            <Grid container sx={{marginBottom: '15px'}}>
                                                <Grid item mobile={12}>
                                                    <Grid item mobile={12} style={{paddingLeft: '30px', marginTop: (topExpanded && (!isMobile)) ? '15px' : '-15px'}}>
                                                        <IconButton color='gray' aria-label="show more" onClick={()=> setTopExpand(!topExpanded)}>
                                                            {topExpanded ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon />}
                                                        </IconButton>
                                                    </Grid>
                                                    {
                                                        topExpanded &&
                                                        <Grid item mobile={12} style={{padding: (!isMobile) ? '0 40px' : '0 20px', marginTop: (isMobile) ? '-40px' : '-72px'}}>
                                                            <Grid container >
                                                                <Grid item mobile={12} tablet={12} full={6}>
                                                                    <Grid container spacing={1} sx={{padding: '20px 40px'}}>
                                                                        <Grid item mobile={12} tablet={6} className={`form-label  `}>
                                                                            Contact First Name
                                                                        </Grid>
                                                                        <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                            <TextField
                                                                                id="outlined-basic"
                                                                                size="small"
                                                                                sx={{width: '100%'}}
                                                                                value={firstName}
                                                                                onChange={(event) => setFirstName(event.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item mobile={12} tablet={6} className={`form-label  `}>
                                                                            Last Name
                                                                        </Grid>
                                                                        <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                            <TextField
                                                                                id="outlined-basic"
                                                                                size="small"
                                                                                sx={{width: '100%'}}
                                                                                value={lastName}
                                                                                onChange={(event) => setLastName(event.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        {
                                                                            targetRecipientValue === 'pricing group' &&

                                                                            <>
                                                                                <Grid item mobile={12} tablet={6} className={`form-label`}>
                                                                                    Email
                                                                                </Grid>
                                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                                    <TextField
                                                                                        id="outlined-basic"
                                                                                        size="small"
                                                                                        sx={{width: '100%'}}
                                                                                        value={email}
                                                                                        onChange={(event) => setEmail(event.target.value)}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item mobile={12} tablet={6} className={`form-label  `}>
                                                                                    Phone
                                                                                </Grid>
                                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                                    <TextField
                                                                                        id="outlined-basic"
                                                                                        size="small"
                                                                                        sx={{width: '100%'}}
                                                                                        value={phone}
                                                                                        onChange={(event) => setPhone(event.target.value)}
                                                                                    />
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={12} full={6} style={{marginTop: isMobile ? '-40px' : '-10px'}}>
                                                                    <Grid container spacing={1} sx={{padding: '30px 40px'}}>
                                                                        <Grid item mobile={12} tablet={6} className={`form-label`}>
                                                                            Address
                                                                        </Grid>
                                                                        <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                            <TextField
                                                                                id="outlined-basic"
                                                                                size="small"
                                                                                sx={{width: '100%'}}
                                                                                value={address}
                                                                                onChange={(event) => setAddress(event.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item mobile={12} tablet={6} className={`form-label  `}>
                                                                            City
                                                                        </Grid>
                                                                        <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                            <TextField
                                                                                id="outlined-basic"
                                                                                size="small"
                                                                                sx={{width: '100%'}}
                                                                                value={city}
                                                                                onChange={(event) => setCity(event.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item mobile={12} tablet={6} className={`form-label  `}>
                                                                            State / Zip
                                                                        </Grid>
                                                                        <Grid item mobile={6} tablet={3} full={3} style={{marginTop: '5px'}}>
                                                                            <TextField
                                                                                id="outlined-basic"
                                                                                size="small"
                                                                                sx={{width: '100%'}}
                                                                                value={state}
                                                                                onChange={(event) => setState(event.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item mobile={6} tablet={3} full={3} style={{marginTop: '5px'}}>
                                                                            <TextField
                                                                                id="outlined-basic"
                                                                                size="small"
                                                                                sx={{width: '100%'}}
                                                                                value={zip}
                                                                                onChange={(event) => setZip(event.target.value)}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{backgroundColor: '#ccc'}}/>
                                            <div className='quote-form-action-area' style={{textAlign: 'left', paddingLeft: '37px', paddingTop:'20px', fontSize: '11pt'}}>
                                                <span>* required</span>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item mobile={12} sx={{marginTop: '40px'}}>
                                    <Card
                                        sx={{
                                            border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                                        }}
                                    >
                                        <div className='quote-form-header-area'>
                                            <Grid container>
                                                <Grid item mobile={12} tablet={6}
                                                      style={{display: 'inline-flex', alignItems: 'center'}}><SettingsIcon
                                                    sx={{margin: '-5px 13px 0 0'}}/>Quote Details</Grid>
                                                <Grid item mobile={12} tablet={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider sx={{backgroundColor: '#ccc'}}/>
                                        <CardContent sx={{padding: '0px !important'}}>
                                            <Grid container spacing={5} className='form-container' style={{marginTop: '0px'}}>
                                                <Grid item mobile={12} tablet={12} full={6}>
                                                    <Grid container spacing={1} sx={{paddingRight: '10px'}}>
                                                        <Grid item mobile={12} tablet={6} className='form-label'>
                                                            Service Type *
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                            <SingleSelect name="Tradelane"
                                                                          values={[
                                                                              {id: 'CY-CY', name: 'CY-CY'},
                                                                              {id: 'CY-DOOR', name: 'CY-DOOR'},
                                                                              {id: 'DOOR-CY', name: 'DOOR-CY'},
                                                                              {id: 'DOOR-DOOR', name: 'DOOR-DOOR'}]}
                                                                          value={serviceType}
                                                                          setChange={setServiceType}

                                                            />
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !tradelane) ? 'form-label-err' : ''}`}>
                                                            Tradelane *
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} full={6} style={{marginTop: '5px'}}>
                                                            <SingleSelect name="Tradelane"
                                                                          values={tradeDirectionData}
                                                                          value={tradelane}
                                                                          setChange={setTradelane}
                                                                          err={validationError && !tradelane}
                                                            />
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} full={6} className={ `form-label ${(validationError && !origin) ? 'form-label-err' : ''}`}>
                                                            Port Of Load *
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} full={6} style={{marginTop: '5px'}}>
                                                            <SingleSelect name="Origin"
                                                                          values={origins}
                                                                          value={origin}
                                                                          setChange={setOrigin}
                                                                          err={validationError && !origin}
                                                            />
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !destination) ? 'form-label-err' : ''}`}>
                                                            Port Of Discharge *
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                            <SingleSelect name="Origin"
                                                                          values={destinations}
                                                                          value={destination}
                                                                          setChange={setDestination}
                                                                          err={validationError && !destination}
                                                            />
                                                        </Grid>
                                                        {(serviceType === 'DOOR-CY' || serviceType === 'DOOR-DOOR') &&
                                                            <>
                                                                <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !polOrigin) ? 'form-label-err' : ''}`}>
                                                                    Bill Of Lading Origin *
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        sx={{width: '100%'}}
                                                                        value={polOrigin}
                                                                        onChange={(event) => setpolOrigin(event.target.value)}
                                                                        error={validationError && !polOrigin}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        }
                                                        {(serviceType === 'CY-DOOR' || serviceType === 'DOOR-DOOR') &&
                                                            <>
                                                                <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !polDestination) ? 'form-label-err' : ''}`}>
                                                                    Bill Of Lading Destination *
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        sx={{width: '100%'}}
                                                                        value={polDestination}
                                                                        onChange={(event) => setpolDestination(event.target.value)}
                                                                        error={validationError && !polDestination}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        }
                                                        {
                                                            (serviceType === 'CY-DOOR' || serviceType === 'DOOR-CY' || serviceType === 'DOOR-DOOR') &&
                                                            <>
                                                                <Grid item mobile={12} tablet={6} className='form-label'>
                                                                    Door Options
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                    <FormControl
                                                                        component="fieldset"
                                                                        variant="standard"
                                                                    >
                                                                        <FormGroup>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox checked={dropAndPull} onChange={(e) => setDropAndPull(e.target.checked)} name="" />
                                                                                }
                                                                                label="Drop And Pull"
                                                                            />
                                                                        </FormGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} className='form-label'>
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '0'}}>
                                                                    <FormControl
                                                                        component="fieldset"
                                                                        variant="standard"
                                                                    >
                                                                        <FormGroup>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox checked={liveLoad} onChange={(e) => setLiveLoad(e.target.checked)} name="" />
                                                                                }
                                                                                label="Live Load"
                                                                            />
                                                                        </FormGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} className='form-label'>
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginBottom: '15px'}}>
                                                                    <FormControl
                                                                        component="fieldset"
                                                                        variant="standard"
                                                                    >
                                                                        <FormGroup>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox checked={liveUnload} onChange={(e) => setLiveUnload(e.target.checked)} name="" />
                                                                                }
                                                                                label="Live Unload"
                                                                            />
                                                                        </FormGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item mobile={12} tablet={12} full={6} style={{marginTop: isMobile ? '-30px' : ''}}>
                                                    <Grid container spacing={1} sx={{paddingRight: '10px'}}>
                                                        <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !equipmentType.length) ? 'form-label-err' : ''}`}>
                                                            Equipment Type *
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                            <MultiSelect name="EquipTypes"
                                                                         values={equipData}
                                                                         value={equipmentType}
                                                                         setChange={setEquipmentType}
                                                                         err={validationError && !equipmentType.length}
                                                            />
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !commodity) ? 'form-label-err' : ''}`}>
                                                            Commodity *
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                            <TextField
                                                                size="small"
                                                                style={{textAlign: 'left', width: '100%'}}
                                                                rows={4}
                                                                value={commodity}
                                                                onChange={(event) => setCommodity(event.target.value)}
                                                                error={validationError && !commodity}
                                                            />
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} className='form-label'>
                                                            New Business *
                                                        </Grid>
                                                        <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                            <FormGroup>
                                                                <Stack direction="row" spacing={1} alignItems="center">
                                                                    <Typography>no</Typography>
                                                                    <Switch checked={newBus}
                                                                            onChange={(e) => setNewBus(e.target.checked)}/>
                                                                    <Typography>yes</Typography>
                                                                </Stack>
                                                            </FormGroup>
                                                        </Grid>
                                                        {newBus &&
                                                            <>
                                                                <Grid item mobile={12} tablet={6} className={ `form-label wrap ${(validationError && !loadsMoved) ? 'form-label-err' : ''}`}>
                                                                    How Loads Are Currently Moved *
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                    <TextField
                                                                        size="small"
                                                                        style={{textAlign: 'left', width: '100%'}}
                                                                        value={loadsMoved}
                                                                        onChange={(event) => setLoadsMoved(event.target.value)}
                                                                        error={validationError && !loadsMoved}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        }
                                                        {
                                                            targetRecipientValue === 'pricing group' &&
                                                            <>
                                                                <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !targetRate) ? 'form-label-err' : ''}`}>
                                                                    Target Rate *
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                    <TextField
                                                                        size="small"
                                                                        style={{textAlign: 'left', width: '100%'}}
                                                                        value={targetRate}
                                                                        onChange={(event) => setTargetRate(event.target.value)}
                                                                        error={validationError && !targetRate}
                                                                    />
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} className={ `form-label`}>
                                                                    Margin
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>
                                                                    <TextField
                                                                        size="small"
                                                                        style={{textAlign: 'left', width: '100%'}}
                                                                        value={margin}
                                                                        onChange={(event) => setMargin(event.target.value)}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        }
                                                        {
                                                            targetRecipientValue === 'pricing group' &&
                                                            <>
                                                                <Grid item mobile={12} tablet={6} className={ `form-label ${(validationError && !volume) ? 'form-label-err' : ''}`}>
                                                                    Volume *
                                                                </Grid>
                                                                <Grid item mobile={12} tablet={6} style={{ marginTop: '5px'}}>
                                                                    <TextField
                                                                        id="outlined-basic"
                                                                        size="small"
                                                                        sx={{width: '100%'}}
                                                                        value={volume}
                                                                        onChange={(event) => setVolume(event.target.value)}
                                                                        error={validationError && !volume}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        }
                                                        {/*<Grid item mobile={12} tablet={6} className="form-label">*/}
                                                        {/*    Image Upload*/}
                                                        {/*</Grid>*/}
                                                        {/*<Grid item mobile={12} tablet={6} style={{marginTop: '5px'}}>*/}
                                                        {/*    <TextField type="file"/>*/}
                                                        {/*</Grid>*/}
                                                    </Grid>
                                                </Grid>
                                                {
                                                    targetRecipientValue === 'pricing group' &&
                                                    <>
                                                        <Grid item mobile={12} className={ `form-label ${(validationError && !justification) ? 'form-label-err' : ''}`} style={{marginTop: '17px', maxWidth: '23.5%'}}>
                                                            Competitive Justification *
                                                        </Grid>
                                                        <Grid item mobile={12} style={{marginTop:'-25px'}}>
                                                            <TextField
                                                                size="small"
                                                                style={{textAlign: 'left', width: '100%'}}
                                                                rows={7}
                                                                multiline={true}
                                                                value={justification}
                                                                onChange={(event) => setJustification(event.target.value)}
                                                                error={validationError && !justification}
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </CardContent>
                                        <CardContent sx={{padding: '0px !important', margin: '30px 0'}}>
                                            <Divider sx={{backgroundColor: '#ccc'}}/>
                                        </CardContent>
                                        <CardContent sx={{padding: '0px !important'}}>
                                            <Grid container spacing={1}>
                                                <Grid item mobile={12} style={{paddingLeft: '30px', marginTop: botExpanded ? '15px' : '-15px', marginBottom: '10px'}}>
                                                    <IconButton color='gray' aria-label="show more">
                                                        <IconButton color='gray' aria-label="show more" onClick={()=> setBotExpand(!botExpanded)}>
                                                            {botExpanded ? <IndeterminateCheckBoxOutlinedIcon /> : <AddBoxOutlinedIcon />}
                                                        </IconButton>
                                                    </IconButton>
                                                </Grid>
                                                {
                                                    botExpanded &&
                                                    <Grid item mobile={12} style={{padding: '0 40px 20px 40px', marginTop: '-100px'}}>
                                                        <Grid container>
                                                            <Grid item mobile={12} tablet={12} full={6}>
                                                                <Grid container spacing={5} sx={{padding: '30px 40px'}}>
                                                                    <Grid item mobile={12} tablet={12} full={6}
                                                                          className="form-label wrap"
                                                                          style={{marginTop: isMobile ? '20px' : ''}}
                                                                    >
                                                                        Time Period of Volume (from / to)
                                                                    </Grid>
                                                                    <Grid item mobile={12} tablet={6}  full={3} style={{marginTop: isMobile ? '-20px' : '5px'}}>
                                                                        <DatePicker sx={{minWidth: '100%'}}
                                                                                    onChange={setEffectiveStart}
                                                                                    slotProps={{textField: {size: 'small'}}}/>
                                                                    </Grid>
                                                                    <Grid item mobile={12} tablet={6}  full={3} style={{marginTop: isMobile ? '-20px' : '5px'}}>
                                                                        <DatePicker sx={{minWidth: '100%'}}
                                                                                    onChange={setEffectiveEnd}
                                                                                    slotProps={{textField: {size: 'small'}}}/>
                                                                    </Grid>
                                                                    <Grid item mobile={12} tablet={6} className="form-label">
                                                                        Hazardous
                                                                    </Grid>
                                                                    <Grid item mobile={12} tablet={6} style={{marginTop: isMobile ? '-20px' : '5px'}}>
                                                                        <FormGroup>
                                                                            <Stack direction="row" spacing={1} alignItems="center">
                                                                                <Typography>no</Typography>
                                                                                <Switch checked={hazardous}
                                                                                        onChange={(e) => setHazardous(e.target.checked)}/>
                                                                                <Typography>yes</Typography>
                                                                            </Stack>
                                                                        </FormGroup>
                                                                    </Grid>
                                                                    {hazardous &&
                                                                        <>
                                                                            <Grid item mobile={12} tablet={6} className="form-label">
                                                                                Hazardous UN Number
                                                                            </Grid>
                                                                            <Grid item mobile={12} tablet={6} style={{marginTop: isMobile ? '-20px' :  '5px'}}>
                                                                                <TextField
                                                                                    size="small"
                                                                                    style={{textAlign: 'left', width: '100%'}}
                                                                                    value={hazardousUNNumber}
                                                                                    onChange={(event) => setHazardousUNNumber(event.target.value)}
                                                                                />
                                                                            </Grid>
                                                                        </>
                                                                    }
                                                                    <Grid item mobile={12} tablet={6} className="form-label">
                                                                        Oversized
                                                                    </Grid>
                                                                    <Grid item mobile={12} tablet={6} style={{marginTop: isMobile ? '-20px' : '5px'}}>
                                                                        <FormGroup>
                                                                            <Stack direction="row" spacing={1} alignItems="center">
                                                                                <Typography>no</Typography>
                                                                                <Switch checked={oversized}
                                                                                        onChange={(e) => setOversized(e.target.checked)}/>
                                                                                <Typography>yes</Typography>
                                                                            </Stack>
                                                                        </FormGroup>
                                                                    </Grid>
                                                                    {oversized &&
                                                                        <>
                                                                            {!isMobile &&
                                                                                <Grid item mobile={12} tablet={6} className="form-label">
                                                                                    L/W/H
                                                                                </Grid>
                                                                            }
                                                                            {isMobile &&
                                                                                <Grid item mobile={12} className="form-label" style={{marginTop:'-25px'}}>
                                                                                    Length
                                                                                </Grid>
                                                                            }
                                                                            <Grid item mobile={12} tablet={2} style={{marginTop:isMobile ? '-25px' :  '5px'}}>
                                                                                <TextField
                                                                                    size="small"
                                                                                    style={{textAlign: 'left', width: '100%'}}
                                                                                    value={length}
                                                                                    onChange={(event) => setLength(event.target.value)}
                                                                                />
                                                                            </Grid>
                                                                            {isMobile &&
                                                                                <Grid item mobile={12} className="form-label" style={{marginTop:'-25px'}}>
                                                                                    Width
                                                                                </Grid>
                                                                            }
                                                                            <Grid item mobile={12} tablet={2} style={{marginTop:isMobile ? '-25px' :  '5px'}}>
                                                                                <TextField
                                                                                    size="small"
                                                                                    style={{textAlign: 'left', width: '100%'}}
                                                                                    value={width}
                                                                                    onChange={(event) => setWidth(event.target.value)}
                                                                                />
                                                                            </Grid>
                                                                            {isMobile &&
                                                                                <Grid item mobile={12} className="form-label" style={{marginTop:'-25px'}}>
                                                                                    Height
                                                                                </Grid>
                                                                            }
                                                                            <Grid item mobile={12} tablet={2} style={{marginTop: isMobile ? '-25px' : '5px'}}>
                                                                                <TextField
                                                                                    size="small"
                                                                                    style={{textAlign: 'left', width: '100%'}}
                                                                                    value={height}
                                                                                    onChange={(event) => setHeight(event.target.value)}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item mobile={12} tablet={6}  className="form-label">
                                                                                Weight
                                                                            </Grid>
                                                                            <Grid item mobile={12} tablet={6} style={{marginTop: isMobile ? '-25px' : '5px'}}>
                                                                                <TextField
                                                                                    size="small"
                                                                                    style={{textAlign: 'left', width: '100%'}}
                                                                                    value={weight}
                                                                                    onChange={(event) => setWeight(event.target.value)}
                                                                                />
                                                                            </Grid>
                                                                        </>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item mobile={12} tablet={12} full={6}>
                                                                <Grid container spacing={3} sx={{padding: isMobile ? '0px 40px 0 45px' : '30px 40px 0 35px'}}>
                                                                    {
                                                                        targetRecipientValue === 'customer service' &&
                                                                        <>
                                                                            <Grid item mobile={12} tablet={6} className={`form-label  `}>
                                                                                Volume
                                                                            </Grid>
                                                                            <Grid item mobile={12} tablet={6} style={{ marginTop: isMobile ? '-10px' : '5px'}}>
                                                                                <TextField
                                                                                    id="outlined-basic"
                                                                                    size="small"
                                                                                    sx={{width: '100%'}}
                                                                                    value={volume}
                                                                                    onChange={(event) => setVolume(event.target.value)}
                                                                                />
                                                                            </Grid>
                                                                        </>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Divider sx={{backgroundColor: '#ccc'}}/>
                                            <div className='quote-form-action-area'>
                                                <Grid container style={{width: '100%'}}>
                                                    <Grid item mobile={12} tablet={6} style={{textAlign: 'left',fontSize: '11pt', padding: '3px 0 0 20px'}}>
                                                        <span>* required</span>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item mobile={12} sx={{marginTop: '40px'}}>
                                    <Card
                                        sx={{
                                            border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                                        }}
                                    >
                                        <div className='quote-form-header-area' style={{color: '#fff'}}>
                                            <Grid container>
                                                <Grid item mobile={12} tablet={6}
                                                      style={{display: 'inline-flex', alignItems: 'center'}}>
                                                    <CommentIcon sx={{margin: '-5px 13px 0 0'}}/> Comments</Grid>
                                                <Grid item mobile={12} tablet={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider sx={{backgroundColor: '#ccc'}}/>
                                        <CardContent sx={{padding: '0px !important', marginTop: '40px', backgroundColor: '#fff'}}>
                                            <Grid container spacing={5} sx={{padding: '10px 40px 30px'}}>
                                                <Grid item mobile={12} style={{fontWeight: 'bold'}}>
                                                    Optional Comments
                                                </Grid>
                                                <Grid item mobile={12} style={{marginTop: '-25px'}}>
                                                    <TextField
                                                        size="small"
                                                        style={{textAlign: 'left', width: '100%'}}
                                                        rows={7}
                                                        multiline={true}
                                                        value={comments}
                                                        onChange={(event) => setComments(event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardContent sx={{padding: '0px !important', margin: '30px 0 0 0'}}>
                                            <Divider sx={{backgroundColor: '#ccc'}}/>
                                            <div className='quote-form-action-area' style={{textAlign: 'right', paddingLeft: '37px', paddingTop:'20px', fontSize: '11pt'}}>
                                                <Grid item mobile={12} tablet={12} full={6} style={{float: 'right'}} >
                                                    <button onClick={validateRequired} className='form-save-btn'>
                                                        Save
                                                    </button>
                                                </Grid>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </>
                        }
                        {
                            paramsSaved &&
                            <>
                                <Grid item mobile={12} sx={{marginTop: '0px'}}>
                                    {
                                        urgentQuote &&
                                        <div style={{width: '100%', textAlign: 'center', marginBottom: '25px', fontWeight: 'bold'}}>
                                            <span style={{color: 'red'}}>*** URGENT QUOTE SELECTED ***</span><br/><br/>
                                            <span>NEED QUOTE RETURNED TO SALES BY: {dayjs(urgentQuoteDate).format('MM-DD-YYYY') }</span>
                                        </div>
                                    }
                                    <Card
                                        sx={{
                                            border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                                        }}
                                    >
                                        <div className='quote-form-header-area' style={{color: '#fff'}}>
                                            <Grid container>
                                                <Grid item mobile={12} tablet={12} full={6}
                                                      style={{display: 'inline-flex', alignItems: 'center'}}>
                                                    <DescriptionIcon sx={{margin: '-5px 13px 0 0'}}/>Quote Summary</Grid>
                                                <Grid item mobile={12} tablet={12} full={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider sx={{backgroundColor: '#ccc'}}/>
                                        <CardContent sx={{ padding: '0px !important', marginTop: '40px', backgroundColor: '#fff', width: '100%', textAlign: 'center'}}>
                                            <div style={{width: '100%', textAlign: 'center'}}>
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="4">Customer Information</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan="2"><strong>Company Name</strong></td>
                                                        <td colSpan="2">{customerName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2"><strong>Contact Name</strong></td>
                                                        <td colSpan="2">{contactName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2"><strong>Email</strong></td>
                                                        <td colSpan="2">{email || 'n/a'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2"><strong>Phone</strong></td>
                                                        <td colSpan="2">{phone || 'n/a'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2"><strong>Address</strong></td>
                                                        <td colSpan="2">
                                                            {contactAddress}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <table className="blank">
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan="4" colSpan="4"></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                {isMobile &&
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th colSpan="4">Quote Details</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td><strong><span>Tradelane</span></strong>
                                                            </td>
                                                            <td><span>{tradelane}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Service Type</span></strong>
                                                            </td>
                                                            <td><span>{serviceType}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Commodity</span></strong>
                                                            </td>
                                                            <td><span>{commodity}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>New Business</span></strong>
                                                            </td>
                                                            <td><span>{newBus ? 'yes' : 'no'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Origin</span></strong>
                                                            </td>
                                                            <td><span
                                                            >{origin}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Bill of Lading Origin</span></strong>
                                                            </td>
                                                            <td><span>{polOrigin || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span
                                                            >Destination</span></strong></td>
                                                            <td><span
                                                            >{destination}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Bill of Lading Destination</span></strong>
                                                            </td>
                                                            <td><span>{polDestination || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Oversized</span></strong></td>
                                                            <td><span>{oversized ? 'yes' : 'no'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>
                                                                <span>L/W/H</span></strong></td>
                                                            <td><span>{lwh}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span
                                                            >Equipment Type</span></strong></td>
                                                            <td><span>{equipmentType.join(' ')}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Weight</span></strong></td>
                                                            <td><span>{weight || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Hazardous</span></strong>
                                                            </td>
                                                            <td><span>{hazardous ? 'yes' : 'no'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>How Loads Are <br/>Currently Moved</span></strong>
                                                            </td>
                                                            <td><span>{loadsMoved || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Hazardous UN Number</span></strong></td>
                                                            <td><span>{hazardousUNNumber || 'n/a'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Target Rate</span></strong></td>
                                                            <td><span>{targetRate || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Margin</span></strong>
                                                            </td>
                                                            <td><span>{margin || 'n/a'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Volume</span></strong></td>
                                                            <td><span>{volume || 'n/a'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Time Period Of Volume</span></strong></td>
                                                            <td><span>{tpov || 'n/a'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Drop And Pull</span></strong></td>
                                                            <td><span>{dropAndPull ? 'yes' : 'n/a'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Live Load</span></strong></td>
                                                            <td><span>{liveLoad ? 'yes' : 'n/a'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Live Unload</span></strong></td>
                                                            <td><span>{liveUnload ? 'yes' : 'n/a'}</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                                {!isMobile &&
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th colSpan="4">Quote Details</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td><strong><span>Tradelane</span></strong>
                                                            </td>
                                                            <td><span
                                                            >{tradelane}</span></td>
                                                            <td><strong><span>Service Type</span></strong>
                                                            </td>
                                                            <td><span>{serviceType}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Commodity</span></strong>
                                                            </td>
                                                            <td><span>{commodity}</span>
                                                            </td>

                                                            <td><strong><span>New Business</span></strong>
                                                            </td>
                                                            <td><span>{newBus ? 'yes' : 'no'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Origin</span></strong>
                                                            </td>
                                                            <td><span
                                                            >{origin}</span></td>

                                                            <td><strong><span>Bill of Lading Origin</span></strong>
                                                            </td>
                                                            <td><span>{polOrigin || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span
                                                            >Destination</span></strong></td>
                                                            <td><span
                                                            >{destination}</span></td>

                                                            <td><strong><span>Bill of Lading Destination</span></strong>
                                                            </td>
                                                            <td><span>{polDestination || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Oversized</span></strong>
                                                            </td>
                                                            <td><span>{oversized ? 'yes' : 'no'}</span>
                                                            </td>

                                                            <td><strong>
                                                                <span>L/W/H</span></strong></td>
                                                            <td><span>{lwh}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span
                                                            >Equipment Type</span></strong></td>
                                                            <td><span>{equipmentType.join(' ')}</span></td>
                                                            <td><strong><span>Weight</span></strong></td>
                                                            <td><span>{weight || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Hazardous</span></strong>
                                                            </td>
                                                            <td><span>{hazardous ? 'yes' : 'no'}</span>
                                                            </td>

                                                            <td><strong><span>How Loads Are <br/>Currently Moved</span></strong>
                                                            </td>
                                                            <td><span>{loadsMoved || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Hazardous UN Number</span></strong></td>
                                                            <td><span>{hazardousUNNumber || 'n/a'}</span></td>
                                                            <td><strong><span>Target Rate</span></strong>
                                                            </td>
                                                            <td><span>{targetRate || 'n/a'}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Margin</span></strong>
                                                            </td>
                                                            <td><span>{margin || 'n/a'}</span></td>
                                                            <td><strong><span>Volume</span></strong></td>
                                                            <td><span>{volume || 'n/a'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Time Period Of Volume</span></strong></td>
                                                            <td><span>{tpov || 'n/a'}</span></td>
                                                            <td><strong><span>Drop And Pull</span></strong></td>
                                                            <td><span>{dropAndPull ? 'yes' : 'n/a'}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong><span>Live Load</span></strong></td>
                                                            <td><span>{liveLoad ? 'yes' : 'n/a'}</span></td>
                                                            <td><strong><span>Live Unload</span></strong></td>
                                                            <td><span>{liveUnload ? 'yes' : 'n/a'}</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                                <table className="blank">
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan="4" colSpan="4"></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="4">Competitive Justification</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan="4" style={{padding:'20px'}}>{justification || 'n/a'}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <table className="blank">
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan="4" colSpan="4"></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="4">Comments</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan="4" style={{padding:'20px'}}>
                                                            {comments || 'n/a'}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardContent>
                                        <CardContent sx={{padding: '0px !important', margin: '30px 0 0 0'}}>
                                            <Divider sx={{backgroundColor: '#ccc'}}/>
                                            <div className='quote-form-action-area' style={{textAlign: 'right', paddingLeft: '37px', paddingTop:'20px', fontSize: '11pt'}}>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item mobile={12} sx={{marginTop: '40px'}}>
                                    <Card
                                        sx={{
                                            border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                                        }}
                                    >
                                        <div className='quote-form-header-area' style={{color: '#fff'}}>
                                            <Grid container>
                                                <Grid item mobile={12} tablet={12} full={6}
                                                      style={{display: 'inline-flex', alignItems: 'center'}}>
                                                    <GroupsIcon sx={{margin: '-5px 13px 0 0'}}/>Finalize Quote Request</Grid>
                                                <Grid item mobile={12} tablet={12} full={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider sx={{backgroundColor: '#ccc'}}/>
                                        <CardContent sx={{padding: '0px !important', margin: '30px 0 0 0'}}>
                                            <Divider sx={{backgroundColor: '#ccc'}}/>
                                            <div className='quote-form-action-area' style={{textAlign: 'right', paddingLeft: '37px', paddingTop:'20px', fontSize: '11pt'}}>
                                                <Grid item mobile={12} tablet={12} full={6} style={{float: 'right'}} >
                                                    <button onClick={() => setParamsSaved(false)} className='form-save-btn'>
                                                        Edit Quote
                                                    </button>
                                                    <button onClick={handleQuoteParamsSubmit} className='form-save-btn'>
                                                        Send Quote Request
                                                    </button>
                                                </Grid>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Dialog
                        open={warningDialogOpen}
                        onClose={()=> setWarningDialogOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Warning:  Missing Required Form Elements"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Please ensure that all required form fields have been filled out.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=> setWarningDialogOpen(false)} autoFocus>
                                back
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            </ThemeProvider>
        );
    }
};
