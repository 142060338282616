import * as React from 'react'

const QuoteRequestContext = React.createContext();

const pricingGroupEmails = [
    {
        id: "1pricinggroupalaska@matson.com",
        value: "1pricinggroupalaska@matson.com",
    },
    {
        id: "pricing_oakcorp@matson.com",
        value: "pricing_oakcorp@matson.com",
    }
];

function QuoteRequesetProvider({children}) {
    const [targetRecipient, setTargetRecipient] = React.useState(false);
    const [targetRecipientValue, setTargetRecipientValue] = React.useState('pricing group');
    const [checkBoxList, setCheckBoxList] = React.useState(pricingGroupEmails);
    const [customerName, setCustomerName] = React.useState(null);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [address, setAddress] = React.useState(null);
    const [city, setCity] = React.useState(null);
    const [state, setState] = React.useState(null);
    const [zip, setZip] = React.useState(null);
    const [firstName, setFirstName] = React.useState(null);
    const [lastName, setLastName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [phone, setPhone] = React.useState(null);
    const [serviceType, setServiceType] = React.useState('CY-CY');
    const [hazardous, setHazardous] = React.useState(false);
    const [oversized, setOversized] = React.useState(false);
    const [newBus, setNewBus] = React.useState(false);
    const [tradelane, setTradelane] = React.useState(false);
    const [origin, setOrigin] = React.useState(false);
    const [destination, setDestination] = React.useState(false);
    const [equipmentType, setEquipmentType] = React.useState([]);
    const [commodity, setCommodity] = React.useState();
    const [hazardousUNNumber, setHazardousUNNumber] = React.useState();
    const [effectiveStart, setEffectiveStart] = React.useState(false);
    const [effectiveEnd, setEffectiveEnd] = React.useState(false);
    const [length, setLength] = React.useState();
    const [width, setWidth] = React.useState();
    const [height, setHeight] = React.useState();
    const [loadsMoved, setLoadsMoved] = React.useState();
    const [targetRate, setTargetRate] = React.useState();
    const [margin, setMargin] = React.useState();
    const [volume, setVolume] = React.useState();
    const [justification, setJustification] = React.useState();
    const [weight, setWeight] = React.useState();
    const [polOrigin, setpolOrigin] = React.useState();
    const [polDestination, setpolDestination] = React.useState();
    const [comments, setComments] = React.useState();
    const [emailGroups, setEmailGroups] = React.useState();
    const [topExpanded, setTopExpand] = React.useState(false);
    const [botExpanded, setBotExpand] = React.useState(false);
    const [paramsSaved, setParamsSaved] = React.useState(false);
    const [manualEmail, setManualEmail] = React.useState('');
    const [validationError, setValidationError] = React.useState(false);
    const [warningDialogOpen, setWarningDialogOpen] = React.useState(false);
    const [recordID, setRecordID] = React.useState();
    const [mappings, setMappings] = React.useState();
    const [origins, setOrigins] = React.useState([]);
    const [destinations, setDestinations] = React.useState([]);
    const [urgentQuote, setUrgentQuote] = React.useState(false);
    const [urgentQuoteDate, setUrgentQuoteDate] = React.useState(null);
    const [dropAndPull, setDropAndPull] = React.useState(false);
    const [liveLoad, setLiveLoad] = React.useState(false);
    const [liveUnload, setLiveUnload] = React.useState(false);
    const [selectedEmails, setSelectedEmails] = React.useState([]);
    const [equipData, setEquipData] = React.useState([]);
    const [equipmentDataRaw, setEquipmentDataRaw] = React.useState([]);
    const [tradelaneDataRaw, setTradelaneDataRaw] = React.useState([]);

    const value = {
        targetRecipient, setTargetRecipient,targetRecipientValue, setTargetRecipientValue, checkBoxList,
        setCheckBoxList,customerName, setCustomerName,showSpinner, setShowSpinner,address,
        setAddress,city, setCity,state, setState,zip, setZip,firstName, setFirstName,lastName,
        setLastName,email, setEmail,phone, setPhone,serviceType, setServiceType,hazardous,
        setHazardous,oversized, setOversized,newBus, setNewBus,tradelane, setTradelane,origin,
        setOrigin,destination, setDestination,equipmentType, setEquipmentType,commodity,
        setCommodity,hazardousUNNumber, setHazardousUNNumber,effectiveStart, setEffectiveStart,
        effectiveEnd, setEffectiveEnd,length, setLength,width, setWidth,height, setHeight,
        loadsMoved, setLoadsMoved,targetRate, setTargetRate,margin, setMargin,volume,
        setVolume,justification, setJustification,weight, setWeight,polOrigin, setpolOrigin,
        polDestination, setpolDestination,comments, setComments,emailGroups, setEmailGroups,
        topExpanded, setTopExpand,botExpanded, setBotExpand,paramsSaved, setParamsSaved,manualEmail,
        setManualEmail, validationError, setValidationError,warningDialogOpen, setWarningDialogOpen,
        recordID, setRecordID,mappings, setMappings,origins, setOrigins,destinations, setDestinations,
        urgentQuote, setUrgentQuote,urgentQuoteDate, setUrgentQuoteDate,dropAndPull, setDropAndPull,
        liveLoad, setLiveLoad,liveUnload, setLiveUnload,selectedEmails, setSelectedEmails, equipmentDataRaw,
        setEquipmentDataRaw, equipData, setEquipData, tradelaneDataRaw, setTradelaneDataRaw
    }
    return <QuoteRequestContext.Provider value={value}>{children}</QuoteRequestContext.Provider>
}

export {QuoteRequesetProvider, QuoteRequestContext}