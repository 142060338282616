import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import {
    Authenticator,
    useAuthenticator
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {Button} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

function Login() {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || '/costTool';
    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);

    const startLogin = () => {
        let hostURI;
        window.location.hostname === 'localhost' ? hostURI = encodeURIComponent(`http://localhost:3000`) : hostURI = encodeURIComponent(`https://${window.location.hostname}`);

        const loginUrl = `https://costengine-sso-${process.env.REACT_APP_ENV_NAME}.auth.${process.env.REACT_APP_REGION}.amazoncognito.com/oauth2/authorize?client_id=${process.env.REACT_APP_USER_POOL_APP_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${hostURI}%2Fsso%2Fokta`

        window.open(loginUrl, "_self");
    }

    return (
        <div style={{padding: '45px', textAlign: 'center'}}>
            Please login below.<br/><br/><br/>
                <Button
                    variant="contained"
                    startIcon={<RestartAltIcon/>}
                    style={{marginRight: '15px'}}
                    onClick={startLogin}
                >
                    login
                </Button>
        </div>
    );
}

export default Login;