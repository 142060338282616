import { createSlice } from "@reduxjs/toolkit";
import auth from "../api/authentication";
import axios from "../api/axios";

// FOR FUTURE, CUSTOM LOGIN OUTSIDE OF COGNITO UI.  FOUNDATION FOR AD LOGIN

const initialState = {
  accessToken: "",
};

const slice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    logout(state) {
      // This logout is also being used in our rootReducer.js, to reset the entire state
      state.accessToken = "";
    },
  },
});

export const { reducer } = slice;

// Leave it here until we have a real login with user and password
// export const signIn = (username, password) => async (dispatch) => {
//   const user = await auth.signIn(username, password);
//   dispatch(slice.actions.setUser(user));
// };

export const setAccessToken = (accessToken) => async (dispatch) => {
  dispatch(slice.actions.setAccessToken(accessToken));
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const federatedSignIn = () => async () => {
  await auth.federatedSignIn();
};

export const logout = (username) => async (dispatch) => {
  await auth.logout(username);
  dispatch(slice.actions.logout());
  axios.defaults.headers.common.Authorization = "";
};
