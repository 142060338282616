import { useContext } from "react";
import { SettingsContext } from "../contexts/settingsContext";

const useSettings = () => {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within a Settings Provider')
    }
    return context;
}

export default useSettings;

