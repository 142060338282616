import * as React from 'react';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
// import QuotePDF from './QuotePDF'
import Box from '@mui/material/Box';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
    DataGrid,
    GridActionsCellItem,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector, GridRowModes, useGridApiRef,
} from '@mui/x-data-grid';
import {
    randomId,
} from '@mui/x-data-grid-generator';
import {styled} from "@mui/material/styles";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

// PDF DOWNLOAD SECTION:
const buildExportData = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value;
        });
        return row;
    });

    return data
};

// END PDF

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    border: 0,
    width: '100%',
    padding: 0,
    margin: 0,
    color:
        theme.palette.mode === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.85)",
    fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(","),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .even": {
        backgroundColor: '#fafafa'
    },
    "& .odd": {
        backgroundColor: '#fff'
    },
    "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
        display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
        }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
        }`,
    },
    "& .MuiDataGrid-cell": {
        color:
            theme.palette.mode === "light"
                ? "rgba(0,0,0,.85)"
                : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
        borderRadius: 0,
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#a8a8a8',
        borderRadius: '0px !important',
        color: 'white'
    },
    '& .MuiDataGrid-columnHeader:last-child': {
        border: 0
    },
    '& .MuiDataGrid-columnHeaders': {
        borderRadius: '0px !important',
    },
    '& .super-app-theme--Totals': {
        backgroundColor: "#fff8b6",
        '&:hover, &.Mui-selected': {
            backgroundColor: "#fff8b6 !important"
        }
    },
}));

const skipArray=['test skip'];

const initialRows = [
    {
        id: 1,
        chargeName: '',
        type: '',
        value: ''
    },
    {
        id: 2,
        chargeName: '',
        type: '',
        value: ''
    },
    {
        id: 3,
        chargeName: '',
        type: '',
        value: ''
    }
];

const setAsCash = (num) => {
    if (num && (typeof num === 'number' || !isNaN(num))) {
        return `${Number(num).toLocaleString("en-US", {style:"currency", currency:"USD"})}`;
    }

    return num;
}

const valueFormatter = (params) => {

    if (params.value == null) {
        return '';
    }

    if(params.value.slice(-1) !== '%') {
        return setAsCash(params.value);
    } else {
        return params.value
    }
    // if(skipArray.includes(params.api.getRow(params.id).title)) {
    //     return params.value;
    // }

}

const initialColumns = [
    {field: 'chargeName', headerName: 'Charge Name', minWidth: 300, editable: true, valueFormatter: (params) => valueFormatter(params)},
    {field: 'value', headerName: 'Value', minWidth: 150, editable: true, valueFormatter: (params) => valueFormatter(params)},
    {field: 'calculation', headerName: 'Calculation', minWidth: 300, editable: true},
    {field: 'calcField', headerName: 'Calculated Field(s)', minWidth: 300, editable: true, type: "singleSelect",  valueOptions: ["Fuel Related Surcharge", "Weight"]},
    {field: 'rateBasis', headerName: 'Rate Basis', minWidth: 150, editable: true, type: "singleSelect",  valueOptions: ["PERCENT", "EACH", "PER UNIT (PIECE)"]},
    {field: '', headerName: '', minWidth: 150, flex: 1, align: 'right', valueFormatter: (params) => valueFormatter(params)}
];

const TemplateGrid = (({equipType, setDataGridExportData, templateData}) => {
    const [rows, setRows] = React.useState(initialRows);
    const [cols, setCols] = React.useState(initialColumns);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const apiRef = useGridApiRef();

    const buildTemplate = () => {
        let templateRows = [];

        if(templateData?.length){
            for(let i in templateData) {
                templateRows.push(
                    {
                        id: templateData[i].id || templateData[i].fee_id,
                        chargeName: templateData[i].fee_name,
                        value: templateData[i].value,
                        calculation: templateData[i].calculation,
                        calcField: templateData[i].calc_field,
                        rateBasis: templateData[i].rate_basis,
                    });
            }
            setRows(templateRows);
        }
    }

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleAddNewRow = (title) => {
        const id = randomId();
        setRows((oldRows) => [
            ...oldRows,
            {
                id,
                title: title,
                service1: '',
                service2: '',
                service3: '',
                service4: '',
                service5: '',
                service6: '',
                service7: '',
                isNew: false,
            },
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.View, fieldToFocus: 'title'},
        }));
    };

    useEffect(() => {
        buildTemplate();
    }, [templateData])

    useEffect(()=> {
        setDataGridExportData(buildExportData(apiRef));
    },[rows])
    

    return (
        <div>
            <Box
                sx={{
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    '& .super-app-theme--header': {
                        backgroundColor: 'rgba(255, 7, 0, 0.55)!important',
                    },
                }}
            >
                <StyledDataGrid
                    apiRef={apiRef}
                    rows={rows}
                    columns={cols}
                    showCellRightBorder={true}
                    hideFooter={true}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={(error) => console.log(" ROW UPDATE ERR ", error)}
                    onCellEditStop={(params, event) => {
                        const { id, field } = params;
                        setTimeout(() => {
                            params.value = apiRef.current.getCellValue(id, field);
                        }, 100)

                    }}
                    getRowClassName={(params) => {
                        if(params.id === 'TOTALS') {
                            return `super-app-theme--Totals`
                        } else if (params.indexRelativeToCurrentPage % 2 === 0){
                            return 'even'
                        } else {
                            return 'odd'
                        }
                    }}
                    isCellEditable={(params) => params.id !== 'TOTALS'}
                />
            </Box>
        </div>
    );
});

export default TemplateGrid;