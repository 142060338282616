import axios from "axios";

const context = `https://costengine-sso-${process.env.REACT_APP_ENV_NAME}.auth.${process.env.REACT_APP_REGION}.amazoncognito.com/oauth2/token`

const postObj = {
  grant_type: 'authorization_code',
  redirect_uri: window.location.hostname === 'localhost' ? `http://localhost:3000/sso/okta` : `https://${window.location.host}/sso/okta`,
  client_id: process.env.REACT_APP_OKTA_CLIENT_ID
};

const auth = `${process.env.REACT_APP_USER_POOL_APP_CLIENT_ID}:${process.env.REACT_APP_COGNITO_SECRET}`;
const hashedAuth = btoa(auth);

const config = {
  headers: {
    'accept': 'application/json',
    'authorization': `Basic ${hashedAuth}`,
    'content-type': 'application/x-www-form-urlencoded'
  }
}


export default {
  post: async (authCode) => {
    postObj.code = authCode;
    //console.log(" RUNNING POST CODE FROM API WITH AUTH CODE ", authCode)
    try {
      const response = await axios.post(context, postObj, config);
      return response?.data;
    } catch (e) {
      throw e;
    }
  },
};
