import axios from "axios";
import {Auth} from "aws-amplify";
import {jwtDecode} from "jwt-decode";


//TODO -> set up env vars: process.env.REACT_APP_API_BASE_URL

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_GATEWAY,
    headers: {
        'Content-Type': 'application/json'
    }
});
const axiosRequestInterceptor = async config => {
    const session = await Auth.currentSession();
    const token = session?.idToken.jwtToken;
    const decoded = jwtDecode(token);

    if (decoded) {
        config.headers.username = decoded?.given_name + ' ' + decoded?.family_name;
    }
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
};
instance.interceptors.request.use(axiosRequestInterceptor, e => Promise.reject(e));

export default instance;
