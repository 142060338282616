import * as React from 'react';
import {Outlet, useNavigate, useLocation} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import {deepOrange} from '@mui/material/colors';
import Logo from "../../assets/CostEngineLogo.png";
import Menu from '@mui/material/Menu';
import setUserActivity from '../../api/userActivity';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from "@mui/material/MenuItem";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import useSettings from '../../hooks/useSettings';
import useUser from '../../hooks/useUser';
import dayjs from 'dayjs';
import {jwtDecode} from "jwt-decode";
import {useEffect} from "react";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BuildIcon from '@mui/icons-material/Build';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {ChevronLeft, ExpandLess, ExpandMore, TextSnippet} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import FormGroup from "@mui/material/FormGroup";
import {Stack} from "@mui/material";
import Switch from "@mui/material/Switch";
import {useMediaQuery} from "@mui/system";
import ssoCodeCheck from "../../api/ssoCodeCheck";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} style={{marginTop: '200px'}}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                MATSON ‐ All Rights Reserved
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            full: 1100
        },
    }
});

let drawerWidth = 20;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const defaultTheme = createTheme();


export function Layout() {
    const {
        state: {appVersionData, alertData},
    } = useSettings();

    const { dispatch } = useUser();

    let d1;
    if(appVersionData?.length)  d1 = dayjs(appVersionData[appVersionData.length-1]?.dbCreationDate);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [navWidth, setNavWidth] = React.useState(20);
    const [navIsOpen, setNavIsOpen] = React.useState(false);
    const [bannerLabel, setBannerLabel] = React.useState('');
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [useToggle, setUseToggle] = React.useState(false);
    const isMenuOpen = Boolean(anchorEl);
    const {user, signOut} = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);
    const [isWarningOpen, setWarningOpen] = React.useState(false);
    const [quoteNavOpen, setQuoteNavOpen] = React.useState(false);
    const [pageView, setPageView] = React.useState('');

    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'), {
        defaultMatches: true
    });

    const handleSubNavClick = () => {
        setQuoteNavOpen(!quoteNavOpen);
    };


    const dasToken = user?.signInUserSession?.idToken?.jwtToken;
    const decoded = dasToken ? jwtDecode(dasToken) : undefined;
    const location = useLocation();
    const { pathname } = location;
    const userInitial = decoded?.given_name?.charAt(0).toUpperCase() || user?.email.charAt(0).toUpperCase();
    const navigate = useNavigate();

    //console.log(decoded?.email)
    let checkUser;
    if(decoded) {
        checkUser = ['metzel@matson.com', 'sparker@matson.com', 'apeng@matson.com','skodayam@matson.com','atpotta@matson.com'].indexOf(decoded?.email.toLowerCase()) > -1;
        if((decoded?.["custom:OktaGroups"] == "[\"CostEngine-Super\"]" || decoded?.["custom:OktaGroups"] == "[\"CostEngine-Admin\"]") && !isAdmin && !useToggle) setIsAdmin(true);
        //console.log("DECODED ", decoded.iss)
        if(decoded?.given_name && decoded?.family_name && decoded?.email) {
            //log user activity
            const userObj = {
                firstName: decoded?.given_name,
                lastName: decoded?.family_name,
                userType: decoded?.["custom:OktaGroups"],
                email: decoded?.email,
            };

            setUserActivity.post(userObj).catch(e => console.log("error with post ",e));
        }

    }


    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const toggleUser = () => {
        if(decoded && checkUser) {
            setUseToggle(true);
            const data = {
                globalUserType: !isAdmin ? 'admin' : 'sales',
                isTestUser: checkUser
            };
            //console.log(" RUNNING DISPATCH ON ", data)
            dispatch({type: 'globalUserType', data})
            setIsAdmin(!isAdmin)
        }
    }

    const toggleDrawer = () => {
        if(navIsOpen) setNavWidth(isMobile ? 1 : 20)
        else setNavWidth(isMobile ? '100%' : 250)
        setNavIsOpen(!navIsOpen)
    }

    const handleLogOut = () => {
        setAnchorEl(null);
        signOut();
        navigate('/login');
    };

    const handleGoBackToSearchClick = () => {
        navigate({
            pathname: "/quoteTool/search"
        });
    }

    useEffect(() => {
        if(alertData?.message) setWarningOpen(true);
        setTimeout(() => {
            setWarningOpen(false)
        }, 5000);
    }, [alertData]);

    useEffect(() => {
        const view = new URLSearchParams(window.location.search).get('view');
        if(pathname === '/login') setBannerLabel('Cost Engine Login')
        if(pathname === '/costTool') setBannerLabel('Cost Tool')
        if(pathname === '/quoteTool/newQuote') setBannerLabel('Quote Generator: New Quote')
        if(pathname === '/quoteTool/search') setBannerLabel('Administration: Quote History')
        if(pathname === '/quoteTool/templates') setBannerLabel('Quote Generator: Quote Templates')
        if(pathname === '/quoteTool/quoteRequest') setBannerLabel('Quote Generator: Quote Request')
        if(pathname === '/admin') setBannerLabel('Administration: User Activity')

        if(view == 'edit') {
            setBannerLabel('Quote Generator: Edit Quote')
            setPageView(view);
        }
        if(view == 'clone') {
            setBannerLabel('Quote Generator: Clone Quote')
            setPageView(view);
        }
        if(view !== 'clone' && view !== 'edit' ) {
            setPageView('');
        }
    }, [pathname]);



    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={() => setAnchorEl(null)}
            style={{position: 'absolute', top: '35px', right: '20px'}}
        >
            <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
            {
                (appVersionData && appVersionData[0]?.appVersion) &&
                    <div style={{padding: '18px 14px', borderTop: '1px solid #000', width: '200px'}}>
                        App Version: {appVersionData[appVersionData.length-1]?.appVersion}
                        <br/>DB Version: {appVersionData[appVersionData.length-1]?.dbVersion}
                        <br/>Created: {d1.format("MM/D/YYYY")}
                        <br/>Env: {process.env.REACT_APP_ENV_TYPE}
                    </div>
            }
            {
                checkUser &&
                <div style={{padding: '18px 14px', borderTop: '1px solid #000', width: '200px'}}>
                    <span>User Type:</span><br/>
                    <FormGroup>
                        <Stack direction="row" spacing={1} alignItems="center" onClick={() => toggleUser()}>
                            <span style={{cursor: 'pointer'}}>sales</span>
                            <Switch checked={isAdmin}/>
                            <span style={{cursor: 'pointer'}}>admin</span>
                        </Stack>
                    </FormGroup>
                </div>
            }
        </Menu>
    );


    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" open={false}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                            background: 'white'
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="black"
                            aria-label="open drawer"
                            style={{marginRight: '16px'}}
                            onClick={toggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box
                            sx={{marginTop: 0, flexGrow: 1}}
                        >
                            <img src={Logo} alt="Cost Engine Logo" style={{ height: '45px'}}/>
                        </Box>
                        {user && <Avatar sx={{bgcolor: deepOrange[500], cursor: 'pointer'}}
                                         onClick={handleProfileMenuOpen}>{userInitial}</Avatar>}
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={navIsOpen}
                    className='mui-drawer'
                    sx={{
                        backgroundColor: '#2E3646',
                        width: navWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            backgroundColor: '#2E3646',
                            width: navWidth,
                            boxSizing: 'border-box',
                            color: 'white'
                        },
                    }}
                >
                    <Toolbar />
                    <List
                        sx={{ width: '100%', maxWidth: 360}}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton>
                            <ListItemIcon
                                primaryTypographyProps={{fontSize: isMobile ? '30px' : '1rem'}}
                            >
                                <BuildIcon style={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary="Cost Tool"
                                primaryTypographyProps={{fontSize: isMobile ? '30px' : '1rem'}}
                                onClick={() => {toggleDrawer();navigate('/costTool');}} />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon
                            primaryTypographyProps={{fontSize: isMobile ? '30px' : '1rem'}}
                            >
                                <TextSnippet  style={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{fontSize: isMobile ? '30px' : '1rem'}}
                                primary="Quote Request" onClick={() => {toggleDrawer();navigate('/quoteTool/quoteRequest');}} />
                        </ListItemButton>
                        {checkUser &&
                            <>
                                <ListItemButton onClick={handleSubNavClick}>
                                    <ListItemIcon
                                        primaryTypographyProps={{fontSize: isMobile ? '30px' : '1rem'}}
                                    >
                                        <SettingsApplicationsIcon style={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{fontSize: isMobile ? '30px' : '1rem'}}
                                        primary="Administration" />
                                    {quoteNavOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={quoteNavOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <ChevronRightIcon  style={{color: '#2E3646'}}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primaryTypographyProps={{fontSize: isMobile ? '30px' : '1rem'}}
                                                primary="Quote History" onClick={() => {toggleDrawer();navigate('/quoteTool/search');}}  />
                                        </ListItemButton>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <ChevronRightIcon  style={{color: '#2E3646'}}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primaryTypographyProps={{fontSize: isMobile ? '30px' : '1rem'}}
                                                primary="User Activity" onClick={() => {toggleDrawer();navigate('/admin');}} />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </>

                        }
                    </List>
                    <List sx={{position: 'absolute', bottom: '0px'}}>
                            <ListItem key={'LogOut'} disablePadding>
                                <ListItemButton>
                                    <ListItemText
                                        primaryTypographyProps={{fontSize: isMobile ? '30px' : '1rem'}}
                                        onClick={() => { handleLogOut(); toggleDrawer();}} sx={{textAlign: 'right'}} primary={'Log Out'} />
                                </ListItemButton>
                            </ListItem>
                    </List>

                </Drawer>
                <Box
                    component="main"
                    className='box-main'
                >
                    <Toolbar/>
                    <Box sx={{flexGrow: 1}}>
                        <Grid container spacing={1}>
                            {/*<Grid item xs={6}>*/}
                            {/*    /!*<img src="/CostEngineLogo.png" alt="image" />*!/*/}
                            {/*    {*/}
                            {/*        (pageView === 'edit' || pageView === 'clone') &&*/}
                            {/*        <div style={{*/}
                            {/*            display: 'flex',*/}
                            {/*            alignItems: 'center',*/}
                            {/*            flexWrap: 'wrap',*/}
                            {/*            color: '#597eb7',*/}
                            {/*            fontWeight: 'bold',*/}
                            {/*            fontSize: '11pt',*/}
                            {/*            cursor: 'pointer',*/}
                            {/*            margin: '30px 0 0 15px'*/}
                            {/*        }}*/}
                            {/*             onClick={handleGoBackToSearchClick}*/}
                            {/*        >*/}
                            {/*            <SearchIcon />*/}
                            {/*            <ChevronLeft />*/}
                            {/*            <span>  back to quote search</span>*/}
                            {/*        </div>*/}
                            {/*    }*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} style={{textAlign: 'right', padding: '40px 30px 40px'}} sx={{justifyContent: 'flex-end'}}>
                                <Typography variant="h5" style={{fontWeight: 'bold', color: '#969696', marginRight: '2px'}}>{bannerLabel}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={isWarningOpen}>
                                    <Alert
                                        severity={alertData?.statusType || "info"}
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setWarningOpen(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}>
                                        <AlertTitle>{alertData?.messageTitle}</AlertTitle>
                                        {alertData?.message} {alertData?.error &&`: "${alertData?.error}"`}
                                    </Alert>
                                </Collapse>
                            </Grid>
                            <Grid item xs={12} className='main-body'>
                                <Outlet/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Copyright sx={{pt: 4, textAlign: "center"}}/>
                </Box>
            </Box>
            {renderMenu}
        </ThemeProvider>
    );
}
