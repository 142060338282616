import './Template.css';
import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import quoteApi from '../../../api/quoteData';
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import QuoteGrid from "../NewQuote/QuoteGrid";
import TemplateGrid from "./TemplateGrid";
import TableViewIcon from "@mui/icons-material/TableView";

const getMuSelStyles = (key, searchArr, theme) => ({
  fontWeight: searchArr.indexOf(key) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
});


const tradeDirectionData = [{
  'name': 'North', 'id': 'North',
}, {
  'name': 'South', 'id': 'South',
}, {
  'name': 'East', 'id': 'East',
}, {
  'name': 'West', 'id': 'West',
}, {
  'name': 'Interport/Interisland', 'id': 'Interport / Interisland',
}];

function SingleSelect({name, values, setChange, value, disabled}) {
  const theme = useTheme();

  const handleFeeCheckChange = (event) => {
    const {
      target: {value},
    } = event;

    setChange(value.includes('*') ? ['D20', 'D40', 'D45', 'R20', 'R40'] : value);
  };

  return (<FormControl sx={{margin: '0', minWidth: '100%'}} size="small">
    <Select
        id={`select-${name}`}
        value={value}
        onChange={handleFeeCheckChange}
        disabled={disabled}
    >
      {values.map((val) => {
        return (<MenuItem
            key={val.id}
            value={val.id}
            style={getMuSelStyles(val.name, values, theme)}
        >
          {val.name}
        </MenuItem>);
      })}
    </Select>
  </FormControl>);
}

const Template = () => {
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [quoteFeeTitles, setQuoteFeeTitles] = React.useState(false);
  const [tradelaneData, setTradelaneData] = React.useState([]);
  const [tradelaneRawData, setTradelaneRawData] = React.useState([]);
  const [tradelane, setTradelane] = React.useState('');
  const [direction, setDirection] = React.useState('');
  const [checkedState, setCheckedState] = React.useState();
  const [templateData, setTemplateData] = React.useState();
  const [newFee, setNewFee] = React.useState();
  const [dataGridExportData, setDataGridExportData] = useState([]);

  const getFormData = () => {
    quoteApi.post({postType: 'getTemplateFeeTitles', data: {}}).then((d) => {
      setQuoteFeeTitles(d.results);
      const feeObj = {};
      for(let i in d.results) {
        feeObj['fee_' + d.results[i].fee_id] = false;
      }

      setCheckedState(feeObj);
    }).then(
        quoteApi.post({postType: 'getTradeLanes', data: {}}).then((d) => {
          const tld = d.results.map(tradelaneRow => {
            return {id: tradelaneRow.trade_lane_title, name: tradelaneRow.trade_lane_title}
          });
          setTradelaneRawData(d.results);
          setTradelaneData(tld);
          setShowSpinner(false);
        })
    );
  }

  const getTemplateData = () => {
    if(tradelane && direction) {
      setShowSpinner(true);
      const tradeID = tradelaneRawData.filter(i=> (i.trade_lane_title === tradelane))[0].trade_lane_id;
      quoteApi.post({postType: 'getGridTemplatesByDirAndTrade', data: { tradeID, direction }}).then((d) => {
        let feeData=[];

        if(d?.results.length) {
          feeData = JSON.parse(d?.results[0].fee_template);
          //console.log(" FEE DATA ", feeData)
          const feeObj = {};
          for(let i in quoteFeeTitles) {
            feeObj['fee_' + quoteFeeTitles[i].id] = false;
          }

          //console.log(" TEST ", feeData)

          for(let i in feeData) {
            feeObj['fee_' + feeData[i].id] = true;
          }

          setCheckedState(feeObj);
          setTemplateData(feeData);
        } else {
          setCheckedState([]);
          setTemplateData([]);
        }

        setShowSpinner(false);
      });
    }
  }

  const addFee = () => {
    setShowSpinner(true);

    const data = {
      fee: newFee
    }
    quoteApi.post({postType: 'addFee', data}).then((d) => {
      getFormData();
    })
  }

  const saveTemplate = () => {
    setShowSpinner(true);
    let feeArr = [];

    for(let i in checkedState) {
      if(i.startsWith("fee_") && checkedState[i] == true) {
        const feeId = i.slice(4);
        const feeName = quoteFeeTitles.filter(fee => ( fee.fee_id == feeId ))[0].fee_name;
        const feeTemplateValues = dataGridExportData.filter(fee=>(fee.chargeName == feeName))[0];
        const {calcField, calculation, rateBasis, value} = feeTemplateValues;
        const valueA = value;
        const valueB = value;
        feeArr.push({id: feeId, fee_name: feeName, calc_field: calcField, calculation, value, rate_basis: rateBasis, valueA, valueB });
      }
    }

    const feeTemplate =  JSON.stringify(feeArr, null, 2);

    const tradeID = tradelaneRawData.filter(i=> (i.trade_lane_title === tradelane))[0].trade_lane_id;

    const data = {
      feeTemplate,
      tradeID,
      direction
    }

    quoteApi.post({postType: 'saveTemplate', data}).then((d) => {
      getTemplateData();
    })
  }

  const prepDataForGrid = (checked) => {
    let feeTitlesArr = [];
    let templateItem = '';

    // TODO - make checkedState and array of objects?
    for(let i in checked) {
      if(i.startsWith("fee_") && checked[i] == true)  {
        templateItem = quoteFeeTitles.filter((t) => {
          return t.fee_id === Number(i.slice(4))
        })[0];
        feeTitlesArr.push(templateItem);
      }
    }

    setTemplateData(feeTitlesArr)
  }

  const handleFeeCheckChange = (event) => {
    const checked = {
      ...checkedState,
      [event.target.name]: event.target.checked,
    };

    setCheckedState(checked);
    prepDataForGrid(checked);
  };

  useEffect(()=>{
    getTemplateData();
  }, [tradelane, direction]);

  useEffect(()=>{
    getFormData();
  }, []);

  //console.log(templateData)

  const templateSelected = tradelane && direction;

  if(showSpinner && quoteFeeTitles.length) {
    return (<Box sx={{position: 'absolute', left: '50%'}}>
      <CircularProgress/>
    </Box>)
  } else {
    return (
        <Box sx={{width: '100%'}}>
          <Card
              sx={{
                border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
              }}
          >
            <div className='quote-form-header-area' style={{display: 'inline-flex', alignItems: 'center', width: '100%'}}>
              <SettingsIcon sx={{margin: '-5px 13px 0 0'}}/>Template Definitions
            </div>
            <Divider sx={{backgroundColor: '#ccc'}}/>
            <CardContent sx={{padding: '0px !important'}}>
              <Grid container spacing={0}>
                <Grid item xs={5} sx={{padding: '40px'}}>
                  <Grid container>
                    <Grid item xs={12} sx={{ marginBottom: '20px'}}>
                      <span style={{fontWeight: 'bold'}}>Choose Template</span>
                    </Grid>
                    <Grid item xs={4} className="form-label" style={{fontWeight: 'normal'}}>
                      Tradelane
                    </Grid>
                    <Grid item xs={7} style={{marginTop: '5px'}}>
                      <SingleSelect name="Tradelane"
                                    values={tradelaneData}
                                    setChange={setTradelane}
                                    value={tradelane}
                      />
                    </Grid>
                    <Grid item xs={4} className="form-label" style={{fontWeight: 'normal'}}>
                      Trade direction
                    </Grid>
                    <Grid item xs={7} style={{marginTop: '5px'}}>
                      <SingleSelect name="Trade Direction"
                                    values={tradeDirectionData}
                                    setChange={setDirection}
                                    value={direction}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sx={{maxWidth: '1px!important'}}>
                  <Divider orientation='vertical' sx={{backgroundColor: '#ccc', width: '1px'}} />
                </Grid>
                <Grid item xs={6} sx={{padding: '0!important', flexGrow: '1!important', minWidth: '0', maxWidth: '100%!important'}}>
                    <Grid item xs={12} sx={{padding: '36px 50px 50px', width: '100%'}}>
                      <span style={{fontWeight: 'bold', color: templateSelected ? '' : '#ccc'}}>Fee Selector</span>
                      {templateSelected &&
                          <div className="box" style={{marginTop: '20px'}}>
                            {
                                quoteFeeTitles && quoteFeeTitles.map(fee => {
                                  return(
                                      <div>
                                        <FormControlLabel
                                            sx={{margin: '0'}}
                                            control={
                                              <Checkbox checked={checkedState[`fee_${fee.fee_id}`]} onChange={handleFeeCheckChange} name={`fee_${fee.fee_id}`} sx={{padding: '8px 10px 0 0'}}/>
                                            }
                                            label={<span style={{fontSize: '11pt'}}>{fee.fee_name}</span>}
                                        />
                                      </div>
                                  );
                                })
                            }
                          </div>
                      }
                    </Grid>
                    <Divider sx={{backgroundColor: '#ccc'}}/>
                    <Grid item xs={12} sx={{padding:'36px 50px 50px', width: '100%'}}>
                      <Grid item xs={12}>
                        <span style={{fontWeight: 'bold', color: templateSelected ? '' : '#ccc'}}>Add Additional Fee's and Charges</span>
                      </Grid>
                      <Grid item xs={12} sx={{display: 'flex!important', width: '100%', marginTop: '25px'}}>
                        {templateSelected &&
                          <>
                            <Grid item xs={4} sx={{flex: 1, marginRight: '20px'}}>
                              <TextField
                                  id="outlined-basic"
                                  size="small"
                                  sx={{width: '100%'}}
                                  onChange={(e) => setNewFee(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={8} sx={{display: 'flex!important', width: '100%'}}>
                              <button onClick={addFee} className='form-save-btn' style={{backgroundColor: '#ececec', width: '80px'}}>Add</button>
                            </Grid>
                          </>
                        }
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
              <Divider sx={{backgroundColor: '#ccc'}}/>
              <div className='quote-form-action-area'>
              </div>
            </CardContent>
          </Card>

          <Card
              sx={{
                border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0, marginTop: '40px'
              }}
          >
            <CardContent sx={{padding: '0px !important'}}>
              <div className='quote-form-header-area' style={{display: 'inline-flex', alignItems: 'center', width: '100%'}}>
                <TableViewIcon sx={{margin: '-5px 13px 0 0'}}/>Template Values
              </div>
              <Divider sx={{backgroundColor: '#ccc'}}/>
              <TemplateGrid equipType={[]} setDataGridExportData={setDataGridExportData} templateData={templateData}/>
              <Divider sx={{backgroundColor: '#ccc'}}/>
              <div className='quote-form-action-area'>
                <button onClick={saveTemplate} className='form-save-btn'>
                  Save
                </button>
              </div>
            </CardContent>
          </Card>
        </Box>
    );
  }
};

export default Template;
