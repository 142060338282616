import { Layout } from '../layout/MainLayout';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login, SSOReturn, CostTool, NewQuote, SearchQuotes, Templates, QuoteRequest, Admin } from "../pages";
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

export function RequireAuth({ children }) {
    const location = useLocation();
    const { route } = useAuthenticator((context) => [context.route]);
    if (route !== 'authenticated') {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
}

export default function AppRoutes() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route
                    index
                    element={
                        <RequireAuth>
                            <CostTool />
                        </RequireAuth>
                    }
                />
                <Route
                    index
                    path="/costTool"
                    element={
                        <RequireAuth>
                            <CostTool />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/quoteTool/newQuote"
                    element={

                            <NewQuote />

                    }
                />
                <Route
                    path="/quoteTool/search"
                    element={
                        <RequireAuth>
                            <SearchQuotes />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/quoteTool/quoteRequest"
                    element={
                        <RequireAuth>
                            <QuoteRequest />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/quoteTool/templates"
                    element={
                        <RequireAuth>
                            <Templates />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/admin"
                    element={
                    <RequireAuth>
                        <Admin />
                    </RequireAuth>
                    }
                 />

                <Route path="/login" element={<Login />} />
                <Route path="/sso/okta" element={<SSOReturn />} />
            </Route>
        </Routes>
    </BrowserRouter>
  );
}
