import React from "react";
import AppRoutes from "../../routes";

//console.log("NEW VERSION 7")

function App() {
  return (
      <AppRoutes />
  );
}

export default App;
