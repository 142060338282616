import * as React from 'react'

const SettingsContext = React.createContext();

function settingsReducer(state, action) {
  switch (action.type) {
    case 'addAppVersion': {
      return {appVersionData: action.data}
    }
    case 'triggeredAlert': {
      return {alertData: action.data}
    }
    case 'verificationAlert': {
      return {alertData: action.data}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function SettingsProvider({children}) {
  const [state, dispatch] = React.useReducer(settingsReducer, {})
  const value = {state, dispatch}
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}

export {SettingsProvider, SettingsContext}