import './NewQuote.css';
import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import QuoteGrid from './QuoteGrid';
import Grid from '@mui/material/Grid';
import {Checkbox, Dialog, FormControlLabel, Stack, TextField, Slide, Typography} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {useTheme} from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import SettingsIcon from '@mui/icons-material/Settings';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import SaveIcon from '@mui/icons-material/Save';
import quoteApi from '../../../api/quoteData';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {jwtDecode} from 'jwt-decode';
import QuotePDF from './QuotePDF';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from '@mui/icons-material/Search';
import {PDFViewer} from "@react-pdf/renderer";
import {CheckCircle, ChevronLeft, HourglassBottom, SimCardAlert} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import {createSearchParams, useNavigate} from "react-router-dom";

const dateFormat = 'MM-DD-YYYY';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 0,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const equipData = [{
  'name': 'SELECT ALL', 'id': '*',
}, {
  'name': 'D20', 'id': 'D20',
}, {
  'name': 'D40', 'id': 'D40',
}, {
  'name': 'D45', 'id': 'D45',
}, {
  'name': 'R20', 'id': 'R20',
}, {
  'name': 'R40', 'id': 'R40',
}];

const tradeDirectionData = [{
  'name': 'North', 'id': 'North',
}, {
  'name': 'South', 'id': 'South',
}, {
  'name': 'East', 'id': 'East',
}, {
  'name': 'West', 'id': 'West',
}, {
  'name': 'Interport/Interisland', 'id': 'Interport / Interisland',
}];

const getMuSelStyles = (key, searchArr, theme) => ({
  fontWeight: searchArr.indexOf(key) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
});

function MultiSelect({name, values, setChange, value, disabled}) {
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: {value},
    } = event;

    setChange(value.includes('*') ? ['D20', 'D40', 'D45', 'R20', 'R40'] : value);
  };

  return (<FormControl sx={{margin: '0', minWidth: '100%'}} size="small">
    <Select
      id={`select-${name}`}
      multiple
      value={value}
      onChange={handleChange}
      disabled={disabled}
    >
      {values.map((val) => {
        return (<MenuItem
          key={val.id}
          value={val.id}
          style={getMuSelStyles(val.name, values, theme)}
        >
          {val.name}
        </MenuItem>);
      })}
    </Select>
  </FormControl>);
}

function SingleSelect({name, values, setChange, value, disabled}) {
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: {value},
    } = event;

    setChange(value.includes('*') ? ['D20', 'D40', 'D45', 'R20', 'R40'] : value);
  };

  return (<FormControl sx={{margin: '0', minWidth: '100%'}} size="small">
    <Select
        id={`select-${name}`}
        value={value}
        onChange={handleChange}
        disabled={disabled}
    >
      {values.map((val) => {
        return (<MenuItem
            key={val.id}
            value={val.id}
            style={getMuSelStyles(val.name, values, theme)}
        >
          {val.name}
        </MenuItem>);
      })}
    </Select>
  </FormControl>);
}

export default function NewQuote() {
  const [tradelaneData, setTradelaneData] = React.useState([]);
  const [tradelaneDataRaw, setTradelaneDataRaw] = React.useState([]);
  const [templateData, setTemplateData] = React.useState([]);
  const [tradelane, setTradelane] = React.useState(null);
  const [direction, setDirection] = React.useState(null);
  const [quoteNumber, setQuoteNumber] = React.useState('');
  const [quoteDate, setQuoteDate] = React.useState('');
  const [quoteTime, setQuoteTime] = React.useState('');
  const [shipDate, setShipDate] = React.useState();
  const [startDate, setStartDate] = React.useState(dayjs());
  const [endDate, setEndDate] = React.useState(dayjs().add(1, 'month'));
  const [comments, setComments] = React.useState('');
  const [customerName, setCustomerName] = React.useState('');
  const [address, setAddress] = React.useState();
  const [city, setCity] = React.useState();
  const [state, setState] = React.useState();
  const [zip, setZip] = React.useState();
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [fax, setFax] = React.useState();
  const [loadingLocation, setLoadingLocation] = React.useState();
  const [receiptPort, setReceiptPort] = React.useState();
  const [dischargePort, setDischargePort] = React.useState();
  const [deliveryLocation, setDeliveryLocation] = React.useState();
  const [commodity, setCommodity] = React.useState();
  const [tarrif, setTarrif] = React.useState();
  const [hazardousUnNumber, setHazardousUnNumber] = React.useState();
  const [quantity, setQuantity] = React.useState();
  const [weight, setWeight] = React.useState();
  const [volume, setVolume] = React.useState();
  const [oversized, setOversized] = React.useState(false);
  const [length, setLength] = React.useState();
  const [width, setWidth] = React.useState();
  const [height, setHeight] = React.useState();
  const [equipType, setEquipType] = useState([]);
  const [recordID, setRecordID] = useState([]);
  const [dataGridJSON, setDataGridJSON] = useState([]);
  const [quoteStatus, setQuoteStatus] = useState('incomplete');
  const [modalOpen, setModalOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [editingQuoteParams, setEditingQuoteParams] = useState(true);
  const [editingCustomerInfo, setEditingCustomerInfo] = useState(true);
  const [editingShippingInfo, setEditingShippingInfo] = useState(true);


  const [open, setOpen] = React.useState(false);
  const [wasOpened, setWasOpened] = React.useState(false);

  const pageView = new URLSearchParams(window.location.search).get('view');
  const quoteID = new URLSearchParams(window.location.search).get('id');

  const {user} = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);

  const dasToken = user?.signInUserSession?.idToken?.jwtToken;
  const decoded = dasToken ? jwtDecode(dasToken) : undefined;
  const createdBy = decoded?.given_name + ' ' + decoded?.family_name;

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setWasOpened(true);
  };

  const getFormData = () => {
    quoteApi.post({postType: 'getTradeLanes', data: {}}).then((d) => {
      const tradelanesData = d.results.map(tradelaneRow => {
        return {id: tradelaneRow.trade_lane_title, name: tradelaneRow.trade_lane_title}
      });
      setTradelaneData(tradelanesData);
      setTradelaneDataRaw(d.results);
    })
  };

  const getPreviousQuoteByID = () => {
    setShowSpinner(true);
    setRecordID(quoteID);
    quoteApi.post({postType: 'getQuote', data: {recordID: quoteID}}).then((d) => {
      const res = d?.results[0];
      // if this is a clone, set general quote params to edit mode
      if(pageView === 'clone') {
        setEditingQuoteParams(true);
      } else {
        setEditingQuoteParams(false);
        setTradelane(res?.tradelane);
        setDirection(res?.trade_direction);
        setQuoteNumber(res?.quote_number);
        setQuoteDate(dayjs(res?.creation_date).format(dateFormat));
        setQuoteTime(dayjs(res?.creation_date).format('HH:MM:ss'));
        setShipDate(dayjs(res?.estimated_shipping_date));
        setStartDate(dayjs(res?.effective_from));
        setEndDate(dayjs(res?.effective_to));
        setComments(res?.comments);
      }
      setEditingShippingInfo(false);
      setEditingCustomerInfo( false);


      setCustomerName(res?.company_name);
      setAddress(res?.address);
      setCity(res?.city);
      setState(res?.state);
      setZip(res?.zip);
      setFirstName(res?.contact_first_name);
      setLastName(res?.contact_last_name);
      setEmail(res?.email);
      setPhone(res?.phone);
      setFax(res?.fax);
      setLoadingLocation(res?.loading_location);
      setReceiptPort(res?.receipt_port);
      setDischargePort(res?.discharge_port);
      setDeliveryLocation(res?.delivery_location);
      setCommodity(res?.commodity);
      setTarrif(res?.tarrif);
      setHazardousUnNumber(res?.hazardous_un_number);
      setQuantity(res?.quantity);
      setWeight(res?.weight);
      setVolume(res?.volume);
      setOversized(res?.oversized == null ? false : res?.oversized);
      setLength(res?.length);
      setWidth(res?.width);
      setHeight(res?.height);
      setEquipType(res?.equipment_type == null ? [] : res?.equipment_type.split(','));
      setDataGridJSON(res?.dataGridJSON);
      setQuoteStatus(res?.status || 'incomplete');

      setShowSpinner(false);
    });
  };

  const getTemplateData = () => {
    let tradeID;

    if(tradelane && direction && tradelaneDataRaw.length) {
      tradeID = tradelaneDataRaw.filter((t) => (t.trade_lane_title === tradelane));
      tradeID = tradeID[0]?.trade_lane_id;
    }

    quoteApi.post({postType: 'getGridTemplatesByDirAndTrade', data: { tradeID, direction }}).then((d) => {
      let feeData=[];

      if(d?.results.length) {
        feeData = JSON.parse(d?.results[0].fee_template);
        setTemplateData(feeData);
      }
    });

    setShowSpinner(false);
  }

  const handleQuoteParamsSubmit = () => {
    setShowSpinner(true);
    const quoteData = {
      tradelane, direction, shipDate, startDate, endDate, createdBy, comments,
    };

    setEditingQuoteParams(false);

    if(pageView === 'edit') {
      quoteData.recordID = quoteID || recordID;
    }

    quoteApi.post({postType: pageView === 'edit' ? 'editQuoteData' : 'quoteData',  data: quoteData}).then((d) => {
      if(pageView !== 'edit') {
        setRecordID(d?.results?.insertId);
        setQuoteNumber(d.quoteNumber);
        setQuoteDate(d.currentDate);
        setQuoteTime(d.currentTime);
        if(quoteStatus === 'incomplete') {
          const statusData = {
            status: quoteStatus,
            recordID: d?.results?.insertId
          };

          quoteApi.post({postType: 'setStatus', data: statusData}).then((d) => {
            setShowSpinner(false);
          });
        }
      }
      setShowSpinner(false);
    });


  };

  const handleCustomerSubmit = () => {
    setShowSpinner(true);
    const customerData = {
      recordID, customerName, address, city, state, zip, firstName, lastName, email, phone, fax,
    };

    quoteApi.post({postType: 'customerData', data: customerData}).then((d) => {
      setEditingCustomerInfo(false);
      setShowSpinner(false);
    });
  };

  const handleShipmentSubmit = () => {
    setShowSpinner(true);
    const shipmentData = {
      recordID,
      loadingLocation,
      receiptPort,
      dischargePort,
      deliveryLocation,
      commodity,
      tarrif,
      hazardousUnNumber,
      quantity,
      weight,
      volume,
      oversized,
      length,
      width,
      height,
      equipType,
    };

    quoteApi.post({postType: 'shipmentData', data: shipmentData}).then((d) => {
      setEditingShippingInfo(false);
      setShowSpinner(false);
    });
  };

  const navigate = useNavigate();

  const handleDataGridSubmit = () => {
    setShowSpinner(true);
    quoteApi.post({postType: 'dataGridData', data: {recordID, dataGridJSON}}).then((d) => {
      setEditingShippingInfo(false);
    });
  };

  const handleSetStatus = (status) => {
    setShowSpinner(true);
    const statusData = {
      status,
      recordID
    };

    quoteApi.post({postType: 'setStatus', data: statusData}).then((d) => {
      setQuoteStatus(status);
      setShowSpinner(false);
    });
  };

  const showBottomFormSections  = quoteNumber && equipType.length > 0 && quoteStatus !== 'request';


  useEffect(()=> {
    if(quoteID) {
      getPreviousQuoteByID();
    }
  },[quoteID]);

  useEffect(()=> {
    if(tradelaneDataRaw.length) getTemplateData();
  },[tradelane, direction, tradelaneDataRaw]);

  useEffect(()=> {
    getFormData();
  },[]);

  if(showSpinner) {
    return (<Box sx={{position: 'absolute', left: '50%'}}>
      <CircularProgress/>
    </Box>)
  } else {
    return (
        <>
          <Box sx={{width: '100%'}}>
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card
                        sx={{
                          border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                        }}
                    >
                      <div className='quote-form-header-area'>
                        <Grid container>
                          <Grid item xs={6}
                                style={{display: 'inline-flex', alignItems: 'center'}}><SettingsIcon
                              sx={{margin: '-5px 13px 0 0'}}/>General Quote Params</Grid>
                          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                            {!editingQuoteParams &&
                                <button onClick={() => setEditingQuoteParams(true)}
                                        className='form-save-btn'>Edit</button>
                            }
                          </Grid>
                        </Grid>
                      </div>
                      <Divider sx={{backgroundColor: '#ccc'}}/>
                      <CardContent sx={{padding: '0px !important'}}>
                        <Grid container spacing={1} sx={{padding: '30px 40px'}}>
                          {quoteNumber &&
                              <>
                                <Grid item xs={6} className="form-label">
                                  Quote number
                                </Grid>
                                <Grid item xs={6} style={{marginTop: '5px'}}>
                                  {quoteNumber}
                                </Grid>
                                <Grid item xs={6} className="form-label">
                                  Quote status
                                </Grid>
                                <Grid item xs={6} style={{marginTop: '20px'}}>
                                  {quoteStatus}
                                </Grid>
                                <Grid item xs={6} className="form-label">
                                  Quote date
                                </Grid>
                                <Grid item xs={6} style={{marginTop: '20px'}}>
                                  {quoteDate}
                                </Grid>
                                <Grid item xs={6} className="form-label">
                                  Time
                                </Grid>
                                <Grid item xs={6} style={{marginTop: '20px'}}>
                                  {quoteTime}
                                </Grid>
                              </>
                          }
                          <Grid item xs={6} className="form-label">
                            Tradelane
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {editingQuoteParams ?
                                <SingleSelect name="Tradelane"
                                             values={tradelaneData}
                                             setChange={setTradelane}
                                             value={tradelane}
                                             disabled={false}
                                />
                                :
                                <div style={{marginTop: '15px'}}>{tradelane}</div>
                            }
                          </Grid>
                          <Grid item xs={6} className="form-label">
                            Trade Direction
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {editingQuoteParams ?
                                <SingleSelect name="Tradelane"
                                             values={tradeDirectionData}
                                             setChange={setDirection}
                                             value={direction}
                                             disabled={false}
                                />
                                :
                                <div style={{marginTop: '15px'}}>{direction}</div>
                            }
                          </Grid>
                          <Grid item xs={6} className="form-label">
                            Estimated ship date
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {editingQuoteParams ?
                                <DatePicker value={shipDate} onChange={setShipDate}
                                            sx={{width: '100%'}} minDate={dayjs(new Date())}
                                            slotProps={{textField: {size: 'small'}}}/> :
                                <div
                                    style={{marginTop: '15px'}}>{dayjs(shipDate).format(dateFormat)}</div>

                            }
                          </Grid>
                          <Grid item xs={6} className="form-label">
                            Effective period (from / to)
                          </Grid>
                          {editingQuoteParams ?
                              <>
                                <Grid item xs={3} style={{marginTop: '5px'}}>

                                  <DatePicker value={startDate} onChange={setStartDate}
                                              slotProps={{textField: {size: 'small'}}}/>
                                </Grid>
                                <Grid item xs={3} style={{marginTop: '5px'}}>
                                  <DatePicker value={endDate} onChange={setEndDate}
                                              slotProps={{textField: {size: 'small'}}}/>
                                </Grid>
                              </> :
                              <Grid item xs={6} style={{marginTop: '15px'}}>
                                {dayjs(startDate).format(dateFormat)} to {dayjs(endDate).format(dateFormat)}
                              </Grid>
                          }
                          <Grid item xs={6} className="form-label">
                            Comments (non-pdf)
                          </Grid>
                          <Grid item xs={6} style={{margin: '5px 0 7px'}}>
                            {editingQuoteParams ? <TextField
                                size="small"
                                style={{textAlign: 'left', width: '100%'}}
                                multiline
                                rows={4}
                                value={comments}
                                onChange={(event) => setComments(event.target.value)}
                            /> : <div style={{marginTop: '15px'}}>{comments}</div>}
                          </Grid>
                        </Grid>
                        <Divider sx={{backgroundColor: '#ccc'}}/>
                        <div className='quote-form-action-area'>
                          {editingQuoteParams &&
                              <button onClick={handleQuoteParamsSubmit} className='form-save-btn'>
                                Save
                              </button>
                          }
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                        sx={{
                          border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                        }}
                    >
                      <div className='quote-form-header-area' style={{color: quoteNumber ? '#fff' : '#7a7a7a'}}>
                        <Grid container>
                          <Grid item xs={6}
                                style={{display: 'inline-flex', alignItems: 'center'}}><PersonIcon
                              sx={{margin: '-5px 13px 0 0'}}/> Customer Information</Grid>
                          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                            {!editingCustomerInfo &&
                                <button onClick={() => setEditingCustomerInfo(true)}
                                        className='form-save-btn'>Edit</button>
                            }
                          </Grid>
                        </Grid>
                      </div>
                      <Divider sx={{backgroundColor: '#ccc'}}/>
                      <CardContent sx={{padding: '0px !important', backgroundColor: '#fff'}}>
                        <Grid container spacing={1} sx={{padding: '30px 40px'}}>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Customer name
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingCustomerInfo ?
                                <div style={{marginTop: '15px'}}>{customerName}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={customerName}
                                    onChange={(event) => setCustomerName(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Address
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingCustomerInfo ?
                                <div style={{marginTop: '15px'}}>{address}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={address}
                                    onChange={(event) => setAddress(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            City
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingCustomerInfo ?
                                <div style={{marginTop: '15px'}}>{city}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={city}
                                    onChange={(event) => setCity(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            State / Zip
                          </Grid>
                          <Grid item xs={3} style={{marginTop: '5px'}}>
                            {!editingCustomerInfo ?
                                <div style={{marginTop: '15px'}}>{state}, {zip}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={state}
                                    onChange={(event) => setState(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={3} style={{marginTop: '5px'}}>
                            {editingCustomerInfo &&
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={zip}
                                    onChange={(event) => setZip(event.target.value)}
                                />
                            }
                          </Grid>
                        </Grid>
                        <Divider sx={{backgroundColor: '#ccc'}}/>
                        <Grid container spacing={1} sx={{padding: '20px 40px 40px 40px'}}>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Contact first / last name
                          </Grid>
                          <Grid item xs={3} style={{marginTop: '5px'}}>
                            {!editingCustomerInfo ?
                                <div style={{marginTop: '15px'}}>{firstName} {lastName}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={firstName}
                                    onChange={(event) => setFirstName(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={3} style={{marginTop: '5px'}}>
                            {editingCustomerInfo &&
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={lastName}
                                    onChange={(event) => setLastName(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Email
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingCustomerInfo ?
                                <div style={{marginTop: '15px'}}>{email}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Phone
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingCustomerInfo ?
                                <div style={{marginTop: '15px'}}>{phone}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={phone}
                                    onChange={(event) => setPhone(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Fax
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingCustomerInfo ?
                                <div style={{marginTop: '15px'}}>{fax}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={fax}
                                    onChange={(event) => setFax(event.target.value)}
                                />
                            }
                          </Grid>
                        </Grid>
                        <Divider sx={{backgroundColor: '#ccc'}}/>
                        {quoteNumber &&
                            <div className='quote-form-action-area'>
                              {editingCustomerInfo &&
                                  <button onClick={handleCustomerSubmit} className='form-save-btn'>
                                    Save
                                  </button>
                              }
                            </div>
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card
                        sx={{
                          border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                        }}
                    >
                      <div className='quote-form-header-area' style={{color: quoteNumber ? '#fff' : '#7a7a7a'}}>
                        <Grid container>
                          <Grid item xs={6}
                                style={{
                                  display: 'inline-flex', alignItems: 'center',
                                }}><DirectionsBoatIcon
                              sx={{margin: '-5px 13px 0 0'}}/> Shipment Setup</Grid>
                          <Grid item xs={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                            {!editingShippingInfo &&
                                <button onClick={() => setEditingShippingInfo(true)}
                                        className='form-save-btn'>Edit</button>
                            }
                          </Grid>
                        </Grid>
                      </div>
                      <CardContent sx={{padding: '0px !important'}} style={{backgroundColor: '#fff'}}>
                        <Grid container spacing={1} sx={{padding: '20px 40px 40px 40px'}}>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Place of loading
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{loadingLocation}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={loadingLocation}
                                    onChange={(event) => setLoadingLocation(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Port of receipt
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{receiptPort}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={receiptPort}
                                    onChange={(event) => setReceiptPort(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Place of delivery
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{deliveryLocation}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={deliveryLocation}
                                    onChange={(event) => setDeliveryLocation(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Port of discharge
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{dischargePort}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={dischargePort}
                                    onChange={(event) => setDischargePort(event.target.value)}
                                />
                            }

                          </Grid>
                        </Grid>
                        <Divider sx={{backgroundColor: '#ccc'}}/>
                        <Grid container spacing={1} sx={{padding: '40px'}}>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Commodity
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{commodity}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={commodity}
                                    onChange={(event) => setCommodity(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Tariff description
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{tarrif}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={tarrif}
                                    onChange={(event) => setTarrif(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Hazardous UN number
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{hazardousUnNumber}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={hazardousUnNumber}
                                    onChange={(event) => setHazardousUnNumber(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Quantity
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{quantity}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={quantity}
                                    onChange={(event) => setQuantity(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Weight
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{weight}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={weight}
                                    onChange={(event) => setWeight(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Volume in Cubic ft
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{volume}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={volume}
                                    onChange={(event) => setVolume(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Oversized
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            {!editingShippingInfo ?
                                <div>{typeof oversized === 'boolean' ? oversized.toString() : oversized}</div> :
                                <FormGroup>
                                  <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>no</Typography>
                                    <Switch checked={oversized} disabled={!quoteNumber} onChange={(e) => setOversized(e.target.checked)}/>
                                    <Typography>yes</Typography>
                                  </Stack>
                                </FormGroup>
                            }
                          </Grid>
                        </Grid>
                        <Divider sx={{backgroundColor: '#ccc'}}/>
                        <Grid container spacing={1} sx={{padding: '40px'}}>
                          <Grid item xs={12} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Dimensions in ft.
                          </Grid>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            L/W/H
                          </Grid>
                          <Grid item xs={2} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{length} / {width} / {height}</div> :
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={length}
                                    onChange={(event) => setLength(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={2} style={{marginTop: '5px'}}>
                            {editingShippingInfo &&
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={width}
                                    onChange={(event) => setWidth(event.target.value)}
                                />
                            }
                          </Grid>
                          <Grid item xs={2} style={{marginTop: '5px'}}>
                            {editingShippingInfo &&
                                <TextField
                                    id="outlined-basic"
                                    size="small"
                                    sx={{width: '100%'}}
                                    value={height}
                                    onChange={(event) => setHeight(event.target.value)}
                                />
                            }
                          </Grid>
                          <Divider sx={{backgroundColor: '#ccc'}}/>
                        </Grid>
                        <Divider sx={{backgroundColor: '#ccc'}}/>
                        <Grid container spacing={1} sx={{padding: '40px'}}>
                          <Grid item xs={6} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                            Equipment Types
                          </Grid>
                          <Grid item xs={6} style={{marginTop: '5px'}}>
                            {!editingShippingInfo ?
                                <div style={{marginTop: '15px'}}>{equipType.join(', ')}</div> :
                                <MultiSelect name="Equipment Types"
                                             values={equipData}
                                             setChange={setEquipType}
                                             value={equipType}
                                             disabled={false}
                                />
                            }
                          </Grid>
                          <Divider sx={{backgroundColor: '#ccc'}}/>
                        </Grid>
                        {quoteNumber &&
                        <div className='quote-form-action-area'>
                          {editingShippingInfo &&
                              <button onClick={handleShipmentSubmit} className='form-save-btn'>
                                Save
                              </button>
                          }
                        </div>
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                    <Card
                        sx={{
                          border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                        }}
                    >
                      <div className='quote-form-header-area' style={{color: showBottomFormSections ? '#fff' : '#7a7a7a'}}>
                        <Grid container>
                          <Grid item xs={6} style={{display: 'inline-flex', alignItems: 'center'}}>
                            <BackupTableIcon sx={{margin: '-5px 13px 0 0'}}/>Quote Data Grid
                          </Grid>
                          <Grid item xs={6} sx={{
                            textAlign: 'right', paddingRight: '15px',
                          }}>{/* <button disabled className='form-save-btn disabled'>Edit</button>*/}</Grid>
                        </Grid>
                      </div>
                      <CardContent sx={{padding: '0px !important', margin: '0 !important'}}>
                        {showBottomFormSections &&
                          <>
                          <QuoteGrid equipType={equipType} setDataGridJSON={setDataGridJSON} templateData={templateData}/>
                          <Divider sx={{backgroundColor: '#ccc'}}/>
                          <div className='quote-form-action-area'>
                            <button onClick={handleDataGridSubmit} className='form-save-btn'>Save</button>
                          </div>
                          </>
                        }
                      </CardContent>
                    </Card>
                  </Grid>
              <Grid item xs={12}>
                <Card
                    sx={{
                      border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                    }}
                >
                  <div className='quote-form-header-area' style={{color: showBottomFormSections ? '#fff' : '#7a7a7a'}}>
                    <Grid container>
                      <Grid item xs={6}
                            style={{display: 'inline-flex', alignItems: 'center'}}><CheckIcon
                          sx={{margin: '-5px 13px 0 0'}}/>Minutiae</Grid>
                      <Grid item xs={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                        {showBottomFormSections && <button onClick={handleOpen} className='form-save-btn'>Edit</button>}
                      </Grid>
                    </Grid>
                  </div>
                  <Divider sx={{backgroundColor: '#ccc'}}/>
                  <CardContent sx={{padding: '0px !important'}} style={{backgroundColor: showBottomFormSections ? '#fff' : '#F1F1F1FF'}}>
                    {showBottomFormSections &&
                      <>
                        <div style={{padding: '30px 40px 40px 40px'}}>
                          {!wasOpened &&
                              <span>None selected. Please click 'edit' to choose minutiae for this quote.</span>
                          }
                          {wasOpened && <>
                            <span>Selected parameters:</span>
                            <ul>
                              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                              <li>Proin dapibus mauris eget ultricies commodo.</li>
                              <li>Proin gravida ante non lacus malesuada, quis varius turpis
                                convallis.
                              </li>
                              <li>Suspendisse aliquam odio eget cursus iaculis.</li>
                              <li>Suspendisse pulvinar dolor eu eros sollicitudin, in blandit erat
                                blandit.
                              </li>
                            </ul>
                          </>}
                        </div>
                        <Divider sx={{backgroundColor: '#ccc'}}/>
                        <div className='quote-form-action-area'></div>
                      </>
                    }
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                {
                    showBottomFormSections &&
                    <div style={{width: '100%', textAlign: 'right',
                      display:'inline-block',
                      paddingRight: '2px',
                      color: quoteStatus === 'delivered' ? '#277b18' : quoteStatus === 'incomplete' ?'#edaa00' : quoteStatus === 'in-contract' ? '#32A3D7FF' : '#000'
                    }}>
                      <div style={{
                        display: 'inline'
                      }}>
                        { quoteStatus === 'delivered' &&
                            <CheckCircle sx={{marginRight: '8px', marginBottom: '-6px'}}/>
                        }
                        { quoteStatus === 'incomplete' &&
                            <SimCardAlert sx={{marginRight: '8px', marginBottom: '-6px'}}/>
                        }
                        { quoteStatus === 'in-contract' &&
                            <HourglassBottom sx={{marginRight: '8px', marginBottom: '-6px'}}/>
                        }
                        <div style={{
                          display: 'inline'
                        }}><span style={{fontWeight: 'bold'}}>status:</span> {quoteStatus}</div>
                      </div>
                    </div>
                }
                <Card
                    sx={{
                      border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                    }}
                >
                  <div className='quote-form-header-area' style={{color: showBottomFormSections ? '#fff' : '#7a7a7a'}}>
                    <Grid container>
                      <Grid item xs={6}
                            style={{display: 'inline-flex', alignItems: 'center'}}><SaveIcon
                          sx={{margin: '-5px 13px 0 0'}}/>Quote Administration</Grid>
                      <Grid item xs={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                        {(quoteStatus !== 'incomplete' && quoteStatus !== 'request')  &&
                            <>
                              <button className='form-save-btn' onClick={() => setModalOpen(true)}>View, Download and Print
                                PDF
                              </button>
                              {quoteStatus !== 'delivered' &&
                                  <button className='form-save-btn' onClick={() => handleSetStatus('delivered')}>Set Quote
                                    As Delivered</button>}
                              {quoteStatus !== 'in-contract' &&
                                  <button className='form-save-btn' onClick={() => handleSetStatus('in-contract')}>Set Quote
                                    As In-Contract</button>}
                            </>

                        }

                        {
                          (showBottomFormSections && quoteStatus === 'incomplete') &&
                            <button className='form-save-btn' onClick={() => handleSetStatus('complete')}>Set Quote As
                              Complete</button>
                        }

                      </Grid>
                    </Grid>
                  </div>
                  <Divider sx={{backgroundColor: '#ccc'}}/>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Card
                  sx={{
                    border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                  }}
              >
                <div className='quote-form-header-area'>
                  <Grid container>
                    <Grid item xs={6}
                          style={{display: 'inline-flex', alignItems: 'center'}}><CheckIcon
                        sx={{margin: '-5px 13px 0 0'}}/>Minutiae</Grid>
                  </Grid>
                </div>
                <Divider sx={{backgroundColor: '#ccc'}}/>
                <CardContent sx={{padding: '0px !important'}}>
                  <Grid container spacing={1} sx={{padding: '40px'}}>
                    <Grid item xs={12} className={`form-label ${quoteNumber ? '' :  'disabled-form-label' } `}>
                      Select as many quote notes below as is applicable.
                    </Grid>
                    <Grid item xs={12} style={{margin: '22px 40px 5px'}}>
                      <FormControlLabel control={<Checkbox/>}
                                        label="FLAT RACK WILL NEED TO BE FLIPPED ONTO YOUR LOWBOY IN ..."/>

                    </Grid>
                    <Grid item xs={12} style={{margin: '5px 40px'}}>
                      <FormControlLabel control={<Checkbox/>}
                                        label="SHIPPER/CONSIGNEE IS RESPONSIBLE FOR MAKING ALL HAWAII ..."/>

                    </Grid>
                    <Grid item xs={12} style={{margin: '5px 40px'}}>
                      <FormControlLabel control={<Checkbox/>}
                                        label="FREIGHT OF THIS SIZE/TYPE MOVES ON AN OFFER BASIS ..."/>

                    </Grid>
                    <Grid item xs={12} style={{margin: '5px 40px'}}>
                      <FormControlLabel control={<Checkbox/>}
                                        label="THIS QUOTATION IS BASED UPON INFORMATION PROVIDED BY ..."/>

                    </Grid>
                    <Grid item xs={12} style={{margin: '5px 40px'}}>
                      <FormControlLabel control={<Checkbox/>}
                                        label="SHIPPER IS RESPONSIBLE FOR SECURING CARGO TO ..."/>

                    </Grid>
                  </Grid>
                  <div className='quote-form-action-area'>
                    <button className='form-save-btn' onClick={handleClose}>Save</button>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </Modal>
          <Dialog
              fullScreen
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              TransitionComponent={Transition}
          >
            <Box sx={{width: '100%', textAlign: 'right', paddingRight: '10px'}}>
              <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalOpen(false)}
                  aria-label="close"
              >
                <CloseIcon/>
              </IconButton>
            </Box>
            <PDFViewer width='100%' height='100%'>
              <QuotePDF/>
            </PDFViewer>
          </Dialog>
        </>
    );
  }
};
