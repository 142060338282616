import * as React from 'react';
import {
    DataGrid,
    gridExpandedRowCountSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useMovieData } from '@mui/x-data-grid-generator';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {useState} from "react";
import userActivity from "../../api/userActivity";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";

const columns  = [
    {
        field: 'firstName',
        headerName: 'First name',
        width: 200,
        editable: true,
    },
    {
        field: 'lastName',
        headerName: 'Last name',
        width: 200,
        editable: true,
    },
    {
        field: 'email',
        headerName: 'EMail',
        width: 225,
        editable: true,
    },
    {
        field: 'userType',
        headerName: 'User Type',
        width: 225,
        editable: true,
    },
    {
        field: 'loginDate',
        type: 'date',
        headerName: 'Login Date',
        width: 225,
        editable: true,
        valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY')
    },
    {
        field: 'quoteRequestsSent',
        headerName: 'Total Quote Requests Sent',
        width: 225,
        editable: true,
    }
];
function CustomFooterStatusComponent() {
    const apiRef = useGridApiContext();
    const rowCount = useGridSelector(apiRef, gridExpandedRowCountSelector);
    return (
        <Box sx={{ p: 1, display: 'flex' }}>
            Total Logins: {rowCount}
        </Box>
    );
}

export default function Admin() {
    const [message, setMessage] = React.useState('');
    const data = useMovieData();

    const [showSpinner, setShowSpinner] = React.useState(false);
    const [ gridData, setGridData] = useState([]);



    const getUserActivity = () => {
        setShowSpinner(true);

        userActivity.get().then((d) => {
            setGridData(d.versions);
            setShowSpinner(false);
        });
    };

    React.useEffect(()=> {
        getUserActivity();
    }, [])
    if(showSpinner) {
        return (<Box sx={{position: 'absolute', left: '50%'}}>
            <CircularProgress/>
        </Box>)
    } else {
        return (
            <Stack spacing={2} sx={{width: '100%'}}>
                <Box sx={{height: 300, width: '100%'}}>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                            }}
                        >
                            <div className='quote-form-header-area' style={{color: '#fff'}}>
                                <Grid container>
                                    <Grid item xs={6}
                                          style={{display: 'inline-flex', alignItems: 'center'}}>
                                        <PersonIcon
                                        sx={{margin: '-5px 13px 0 0'}}/> User Login Activity</Grid>
                                    <Grid item xs={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider sx={{backgroundColor: '#ccc'}}/>
                            <CardContent sx={{padding: '0px !important', backgroundColor: '#fff'}}>
                                <DataGrid
                                    rows={gridData}
                                    columns={columns}
                                    getRowId={(row) => row.iduserActivity}
                                    sx={{width: '100% !important', backgroundColor: 'white'}}
                                    slots={{
                                        footer: CustomFooterStatusComponent,
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Box>
            </Stack>
        );
    }
}
