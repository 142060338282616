import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authenticationReducer } from "../slices/authentication";
import { reducer as userReducer } from "../slices/user";
import { reducer as roleReducer } from "../slices/role";
import { reducer as notificationsReducer } from "../slices/notifications";

const appReducer = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  role: roleReducer,
  notifications: notificationsReducer,
});

const rootReducer = (state, action) => {
  // Cleaning every state after logout
  if (action.type === "authentication/logout") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
