import React, { Fragment } from 'react'
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../assets/MatsonLogo.png';

const QuotePDF = () => {

    const reciept_data = {
        "id": "642be0b4bbe5d71a5341dfb1",
        "quote_number": "20200669",
        "address": "123 Main St.  Oakland CA 12345",
        "date": "01/16/2024",
        "items": [
            {
                "id": 1,
                "colA": "Equipment",
                "colB": "D20",
                "colC": "D40",
                "colD": "D45",
            },
            {
                "id": 2,
                "colA": "Base Rate",
                "colB": "$4,390.00",
                "colC": "$4,929.00",
                "colD": "$5,225.00",
            },
            {
                "id": 3,
                "colA": "West Coast Terminal Charge",
                "colB": "$1,140.00",
                "colC": "$1,140.00",
                "colD": "$1,140.00",
            },
            {
                "id": 4,
                "colA": "Fuel Surcharge Percentage",
                "colB": "42%",
                "colC": "42%",
                "colD": "42%",
            },
            {
                "id": 5,
                "colA": "Fuel Surcharge",
                "colB": "$1,844.00",
                "colC": "$2,070.00",
                "colD": "$2,195.00",
            },
            {
                "id": 6,
                "colA": "Guam Terminal Charge",
                "colB": "$992.00",
                "colC": "$992.00",
                "colD": "$992.00",
            },
            {
                "id": 7,
                "colA": "Invasive Species Fee",
                "colB": "$26.00",
                "colC": "$52.00",
                "colD": "$58.00",
            }
        ]
    }

    const styles = StyleSheet.create({
        pageOne: {fontSize: 11, paddingTop: 20, lineHeight: 1.5,flexDirection: 'column' },

        pageTwo: {fontSize: 11, padding: 0, margin: 0, lineHeight: 1.5, flexDirection: 'column' },

        spaceBetween : {flex : 1,flexDirection: 'row',alignItems:'center',justifyContent:'space-between',color: "#3E3E3E" },

        titleContainer: {flexDirection: 'row',marginTop: 24},

        logo: { width: 90 },

        reportTitle: {  fontSize: 16,  textAlign: 'center' },

        addressTitle : {fontSize: 11,fontStyle: 'bold'},

        footerCopyright : {fontSize: 6,fontStyle: 'bold', textAlign: 'center', marginTop: '20px'},

        quoteNumber : {fontSize: 11,fontStyle: 'bold',marginTop: 20},

        invoice : {fontWeight: '400',fontSize: 20},

        invoiceNumber : {fontSize: 11,fontWeight: '400'},

        address : { fontWeight: 400, fontSize: 10},

        theader : {marginTop : 0,fontSize : 10,fontStyle: '400',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theader2 : { flex:2, borderRightWidth:0, borderBottomWidth:1},

        tbody:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},

        total:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1.5, borderColor : 'whitesmoke', borderBottomWidth:1},

        tbody2:{ flex:2, borderRightWidth:1, },

        quoteFooter: {
            position: 'absolute',
            fontSize: 12,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#0039a6",
            padding: "20",
            color: 'white'
        },

    });

    const InvoiceTitle = () => (
        <View>
            <View style={styles.titleContainer}>
                <View style={styles.spaceBetween}>
                    <Image style={styles.logo} src={logo} />
                </View>
            </View>
            <View>
                <Text style={{textAlign: 'center', fontSize: '16', fontWeight: 'ultrabold', marginTop: '40'}}>Shipping Quote</Text>
            </View>
        </View>
    );

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.invoice}></Text>
                </View>
                <View>
                    <Text style={styles.addressTitle} style={{marginTop: '20px'}}>Quote number: {reciept_data.quote_number} </Text>
                    <Text style={styles.addressTitle}>Generated: {reciept_data.date}</Text>
                    <Text style={styles.addressTitle}>Valid: 01/16/2024 - 05/01/2024</Text>
                    <Text style={styles.quoteNumber}>Matson Contact:</Text>
                    <Text style={styles.addressTitle}>John Doe</Text>
                    <Text style={styles.addressTitle}>123 Main st</Text>
                    <Text style={styles.addressTitle}>Oakland, CA</Text>
                    <Text style={styles.addressTitle}>12345</Text>
                    <Text style={styles.quoteNumber}>555.555.5555</Text>
                    <Text style={styles.addressTitle}>jdoe@matson.com</Text>

                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{maxWidth : 200}}>
                    <Text style={styles.addressTitle} style={{fontWeight: 'bold'}}>Quote Generated For:</Text>
                    <Text style={styles.addressTitle} style={{marginTop: '20'}}>Jane Doe</Text>
                    <Text style={styles.addressTitle}>ABC Corporation</Text>
                    <Text style={styles.address}>123 Main St</Text>
                    <Text style={styles.address}>Omaha, NE 12345</Text>
                </View>
            </View>
        </View>
    );

    const FooterMain = () => (
        <View style={{ backgroundColor: "#0039a6", flex: 1, padding: "20 20 0 20", marginTop:80, color: 'white'}}>
            <Text style={styles.addressTitle}>This is where legal warnings about length of quote, instructions, privacy of quote, terms and conditions, etc can go. </Text>
            <Text style={styles.addressTitle} style={{marginTop: '20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lacus mauris, sollicitudin sit amet augue id, pellentesque efficitur turpis. Etiam eleifend neque sed pretium blandit. Duis ullamcorper nisl neque. Nam ultrices fermentum malesuada. Suspendisse sit amet tincidunt sapien, eget aliquam velit. Aliquam scelerisque egestas lacus sed tincidunt. Vivamus id tincidunt lacus, ut ornare diam. Nullam at pellentesque libero. Nunc iaculis, tellus at vulputate ornare, mauris quam cursus nunc, at convallis mi nisl ac libero. Praesent laoreet euismod risus, eget malesuada ante dictum non. Ut gravida eros nec justo commodo, scelerisque tincidunt mauris suscipit. Nullam pharetra id dui non gravida. Curabitur viverra ante ex, sit amet tempor erat feugiat eget. Etiam volutpat risus vel ante cursus, nec ultrices neque mollis. In sagittis urna luctus ante molestie convallis. Nulla auctor ultrices enim at vestibulum. </Text>
            <Text style={styles.footerCopyright}>©2024 MATSON ‐ All Rights Reserved</Text>
        </View>
    );


    const TableHead = () => (
        <View style={{width: '100%'}}>
            <View style={{ width:'100%', flexDirection :'row', margin: 0}}>
                <View style={[styles.theader, styles.theader2]}>
                    <Text >Service</Text>
                </View>
                <View style={styles.theader}>
                    <Text>Port To Port</Text>
                </View>
                <View style={styles.theader}>
                    <Text>Port To Port</Text>
                </View>
                <View style={styles.theader}>
                    <Text>Port To Port</Text>
                </View>
            </View>
            <View style={{ width:'100%', flexDirection :'row', margin: 0}}>
                <View style={[styles.theader, styles.theader2]}>
                    <Text >Origin</Text>
                </View>
                <View style={styles.theader}>
                    <Text>Oakland</Text>
                </View>
                <View style={styles.theader}>
                    <Text>Oakland</Text>
                </View>
                <View style={styles.theader}>
                    <Text>Oakland</Text>
                </View>
            </View>
            <View style={{ width:'100%', flexDirection :'row', margin: 0}}>
                <View style={[styles.theader, styles.theader2]}>
                    <Text >Destination</Text>
                </View>
                <View style={styles.theader}>
                    <Text>Guam</Text>
                </View>
                <View style={styles.theader}>
                    <Text>Guam</Text>
                </View>
                <View style={styles.theader}>
                    <Text>Guam</Text>
                </View>
            </View>
        </View>
    );


    const TableBody = () => (
        reciept_data.items.map((receipt)=>(
            <Fragment key={receipt.id}>
                <View style={{ width:'100%', flexDirection :'row'}}>
                    <View style={[styles.tbody, styles.tbody2]}>
                        <Text >{receipt.colA}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.colB} </Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.colC}</Text>
                    </View>
                    <View style={styles.tbody}>
                        <Text>{receipt.colD}</Text>
                    </View>
                </View>
            </Fragment>
        ))
    );

    const TableTotal = () => (
            <View style={{ width:'100%', flexDirection :'row', backgroundColor: '#fff8b6'}}>
                <View style={[styles.tbody, styles.tbody2]}>
                    <Text >Total Per Container to Guam</Text>
                </View>
                <View style={styles.tbody}>
                    <Text>$8,326.00</Text>
                </View>
                <View style={styles.tbody}>
                    <Text>$9,183.00</Text>
                </View>
                <View style={styles.tbody}>
                    <Text>$9,610.50</Text>
                </View>
            </View>
    );

    const QuoteFooter = () => (
        <View style={styles.quoteFooter}>
            <Text style={{fontSize: 6,fontStyle: 'bold', textAlign: 'center'}}>©2024 MATSON ‐ All Rights Reserved</Text>
        </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.pageOne}>
                <View style={{paddingLeft: 40,paddingRight: 40}}>
                    <InvoiceTitle/>
                    <Address/>
                    <UserAddress/>
                </View>
                <FooterMain/>
            </Page>
            <Page size="A4" style={styles.pageTwo}>
                <TableHead/>
                <TableBody/>
                <TableTotal/>
                <QuoteFooter />
            </Page>
        </Document>

    );
}

export default QuotePDF