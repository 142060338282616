import React from "react";
import {Provider as ReduxProvider} from "react-redux";
import { Authenticator } from "@aws-amplify/ui-react";
import {Amplify} from 'aws-amplify';
import {SettingsProvider} from "./contexts/settingsContext";
import {UserProvider} from "./contexts/userContext";
import {createRoot} from 'react-dom/client';
import store from "./store";
import aws_exports from "./awsExports";
import App from "./pages/App";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import './index.css';
import awsConfig from "./awsExports";
import {QuoteRequesetProvider} from "./contexts/quoteRequestContext";

const container = document.getElementById('root');
const root = createRoot(container);

console.log(" DEPLOYMENT AK FINAL")

Amplify.configure({
    Auth: {
        region: awsConfig.REGION,
        userPoolId: awsConfig.USER_POOL_ID,
        userPoolWebClientId: awsConfig.USER_POOL_APP_CLIENT_ID
    }
})
root.render(
        <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SettingsProvider>
                    <UserProvider>
                        <QuoteRequesetProvider>
                            <Authenticator.Provider>
                                <App/>
                                { /* react dev tools <ReactQueryDevtools initialIsOpen={false} /> */}
                            </Authenticator.Provider>
                        </QuoteRequesetProvider>
                    </UserProvider>
                </SettingsProvider>
            </LocalizationProvider>
        </ReduxProvider>);


