/* eslint-disable camelcase */
import axios from "../axios";

const context = "/quoteData";

export default {
  post: async (postObj) => {
    //console.log(postObj)
    try {
      const response = await axios.post(context, JSON.stringify(postObj));
      return response?.data;
    } catch (e) {
      throw e
    }
  },
};
