import * as React from 'react'

const UserContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'globalUserType': {
      return {globalUserType: action.data.globalUserType, isTestUser: action.data.isTestUser}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function UserProvider({children}) {
  const [state, dispatch] = React.useReducer(userReducer, {})
  const value = {state, dispatch}
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export {UserProvider, UserContext}