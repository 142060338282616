import { useContext } from "react";
import { QuoteRequestContext } from "../contexts/quoteRequestContext";

export const useQuoteRequest = () => {
    const context = useContext(QuoteRequestContext);
    if (context === undefined) {
        throw new Error('useQuoteRequest must be inside a useQuoteRequestProvider');
    }
    return context;
}


