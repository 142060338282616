import './NewQuote.css';
import * as React from 'react';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
// import QuotePDF from './QuotePDF'
import Box from '@mui/material/Box';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
    DataGrid,
    GridActionsCellItem,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector, GridRowModes, useGridApiRef,
} from '@mui/x-data-grid';
import {
    randomId,
} from '@mui/x-data-grid-generator';
import {styled} from "@mui/material/styles";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

// PDF DOWNLOAD SECTION:
const getJson = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value;
        });
        return row;
    });

    return JSON.stringify(data, null, 2);
};

// END PDF

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    border: 0,
    width: '100%',
    padding: 0,
    margin: 0,
    color:
        theme.palette.mode === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.85)",
    fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(","),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .even": {
        backgroundColor: '#fafafa'
    },
    "& .odd": {
        backgroundColor: '#fff'
    },
    "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
        display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
        }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
        }`,
    },
    "& .MuiDataGrid-cell": {
        color:
            theme.palette.mode === "light"
                ? "rgba(0,0,0,.85)"
                : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
        borderRadius: 0,
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#d1d1d1',
        borderRadius: '0px !important'
    },
    '& .super-app-theme--Totals': {
        backgroundColor: "#fff8b6",
        '&:hover, &.Mui-selected': {
            backgroundColor: "#fff8b6 !important"
        }
    },
}));

const skipArray=['test skip'];

const initialRows = [
    {
        id: 1,
        title: '',
        service1: '',
        service2: '',
        service3: '',
        service4: '',
        service5: '',
        service6: '',
        service7: '',
    },
    {
        id: 2,
        title: '',
        service1: '',
        service2: '',
        service3: '',
        service4: '',
        service5: '',
        service7: '',
    },
    {
        id: 3,
        title: '',
        service1: '',
        service2: '',
        service3: '',
        service4: '',
        service5: '',
        service6: '',
        service7: '',
    }
];

const setAsCash = (num) => {
    if (num && (typeof num === 'number' || !isNaN(num))) {
        return `${Number(num).toLocaleString("en-US", {style:"currency", currency:"USD"})}`;
    }

    return num;
}

const valueFormatter = (params) => {
   // //console.log(" PARAMS  ", params.api.getRow(params.id))
    if (params.value == null) {
        return '';
    }
    if(params.value.slice(-1) !== '%') {
        return setAsCash(params.value);
    } else {
        return params.value
    }
    // if(skipArray.includes(params.api.getRow(params.id).title)) {
    //     return params.value;
    // }
    return setAsCash(params.value);
}

// const initialColumns = [
//     {field: 'value', headerName: 'D20 Value', minWidth: 150, editable: true},
//     {field: 'dogs', headerName: 'dogs', minWidth: 150, editable: true},
//     {field: 'fee_id', headerName: 'fee', minWidth: 150, editable: true},
//     {field: 'chargeName', headerName: 'Tarrif / Item', minWidth: 300, editable: true},
//     {field: 'quantity', headerName: 'Quantity', minWidth: 150, editable: true},
//     {field: 'rateBasis', headerName: 'Rate Basis', minWidth: 150, editable: true},
// ];


const spacerCol =     {field: 'rateBasi', headerName: '', flex: 1, editable: true};

function updateSummaryRow(apiRef) {
    const gridData = JSON.parse(getJson(apiRef));
    let col1Total= 0;
    let col2Total = 0;
    let col3Total = 0;
    let col4Total = 0;
    let col5Total = 0;
    let col6Total = 0;


    if(gridData){
        //look for numbers to add
        gridData.map((row)=>{
            if(row.title !== 'totals:'){
                if(row?.service1 && !isNaN(row.service1) && !skipArray.includes(row.title)) col1Total += Number(row.service1);
                if(row?.service2 && !isNaN(row.service2) && !skipArray.includes(row.title)) col2Total += Number(row.service2);
                if(row?.service3 && !isNaN(row.service3) && !skipArray.includes(row.title)) col3Total += Number(row.service3);
                if(row?.service4 && !isNaN(row.service4) && !skipArray.includes(row.title)) col4Total += Number(row.service4);
                if(row?.service5 && !isNaN(row.service5) && !skipArray.includes(row.title)) col5Total += Number(row.service5);
                if(row?.service6 && !isNaN(row.service6) && !skipArray.includes(row.title)) col6Total += Number(row.service6);
            }
        })
    }


    const returnObj = {
        id: 'TOTALS',
        title: 'totals:',
        service1: col1Total > 0 ? col1Total : '',
        service2: col2Total > 0 ? col2Total : '',
        service3: col3Total > 0 ? col3Total : '',
        service4: col4Total > 0 ? col4Total : '',
        service5: col5Total > 0 ? col5Total : '',
        service6: col6Total > 0 ? col6Total : '',
        rowType: 'Totals',
        isNew: false,
    }

    return returnObj;
}

const summaryRowDef = {
    id: 'TOTALS',
    title: 'totals:',
    service1: '',
    service2: '',
    service3: '',
    service4: '',
    service5: '',
    service6: '',
    service7: '',
    rowType: 'Totals',
    isNew: false,
}

const QuoteGrid = (({equipType, setDataGridJSON, templateData}) => {
    const [rows, setRows] = React.useState();
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [summaryRow, setSummaryRowDef] = React.useState(summaryRowDef);
    const handleDeleteClick = (id) => () => {
        setRows(rows.filter((row) => row.id !== id));
    };
    const apiRef = useGridApiRef();

    const buildTemplate = () => {
        let templateRows = [];

        for(let i in templateData) {
            templateRows.push(
                {
                    id: templateData[i].id,
                    fee_name: templateData[i].fee_name,
                    quantity: '',
                    valueA: templateData[i].value,
                    valueB: templateData[i].value,
                    rate_basis: templateData[i].rate_basis,
                });
        }
        setRows(templateRows);
    }

    const handleAddNewRow = (tile) => {
        // const id = randomId();
        // setRows((oldRows) => [
        //     ...oldRows,
        //     {
        //     id,
        //     fee_name: '',
        //     quantity: '',
        //     valueA: '',
        //     valueB: '',
        //     rate_basis: '',
        //     isNew: false,
        //     },
        // ]);
        // setRowModesModel((oldModel) => ({
        //     ...oldModel,
        //     [id]: {mode: GridRowModes.View, fieldToFocus: 'id'},
        // }));
    };


    const actionsObj = {
        field: 'actions',
        type: 'actions',
        headerName: 'Delete',
        minWidth: 50,
        align: 'center',
        cellClassName: 'actions',
        getActions: ({id}) => {
            if(id !== 'TOTALS'){
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            } else {
                return [];
            }

        },
    };

    const cols = [
        {field: 'fee_name', headerName: 'Tariff / Item', minWidth: 300, editable: true},
        {field: 'quantity', headerName: 'Quantity', minWidth: 150, editable: true},
        {field: 'rate_basis', headerName: 'Rate Basis', minWidth: 150, editable: true},
        {field: 'valueA', headerName: 'D20 Rate', minWidth: 150, editable: true, valueFormatter: (params) => valueFormatter(params)},
        {field: 'valueB', headerName: 'D40 Rate', minWidth: 150, editable: true, valueFormatter: (params) => valueFormatter(params)},
        {field: '', headerName: '', flex: 1, editable: true},
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Delete',
            minWidth: 50,
            align: 'center',
            cellClassName: 'actions',
            getActions: ({id}) => {
                if(id !== 'TOTALS'){
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ];
                } else {
                    return [];
                }

            },
        }
    ];


    // useEffect(()=> {
    //     setDataGridJSON(getJson(apiRef));
    // },[rows])

    useEffect(() => {
        if(templateData && templateData?.length > 0) {
            buildTemplate(templateData)
        }
    }, [templateData])

    // TODO - make pdf name dynamic
    if(equipType.length && templateData.length){
        return (
            <div style={{marginTop: '-50px'}}>
                <div style={{float: 'right', margin: '0 10px 10px'}}>
                    <IconButton onClick={() => handleAddNewRow()}
                                aria-label="add new row" sx={{fontSize: '11px', color: 'white'}}>
                        add row<AddCircleOutlineIcon style={{marginLeft: "5px"}}/>
                    </IconButton>
                    {/*<PDFDownloadLink document={<QuotePDF />} fileName='MatsonQuote.pdf'>*/}
                    {/*    <IconButton aria-label="download pdf" sx={{fontSize: '11px', color: 'white'}}>*/}
                    {/*        download quote<PictureAsPdfIcon style={{marginLeft: "5px"}}/>*/}
                    {/*    </IconButton>*/}
                    {/*</PDFDownloadLink>*/}

                </div>
                <Box
                    sx={{
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        },
                        '& .super-app-theme--header': {
                            backgroundColor: 'rgba(255, 7, 0, 0.55)!important',
                        },
                    }}
                >{
                    rows && rows?.length > 0 &&
                    <StyledDataGrid
                        apiRef={apiRef}
                        rows={rows}
                        columns={cols}
                        showCellRightBorder={true}
                        hideFooter={true}
                        getRowClassName={(params) => {
                            if(params.id === 'TOTALS') {
                                return `super-app-theme--Totals`
                            } else if (params.indexRelativeToCurrentPage % 2 === 0){
                                return 'even'
                            } else {
                                return 'odd'
                            }
                        }}
                    />

                }
                </Box>
            </div>
        );
    } else {
        return (<></>)
    }
});

export default QuoteGrid;