import './SearchQuotes.css';
import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import quoteApi from '../../../api/quoteData';
import {
  DataGrid,
  GridActionsCellItem, gridExpandedRowCountSelector,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import {createSearchParams, useNavigate} from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import {styled} from "@mui/material/styles";
import { PDFViewer } from '@react-pdf/renderer';
import QuotePDF from './QuotePDF';
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { Dialog, Slide} from "@mui/material";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {CheckCircle, HourglassBottom, SimCardAlert, Visibility} from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from "@mui/material/CircularProgress";

function CustomToolbar() {
  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridExpandedRowCountSelector);
  return (
      <GridToolbarContainer sx={{width: '100%', padding: '20px'}}>
        <Grid container>
          <Grid item xs={6}>
            <GridToolbarColumnsButton style={{ color: '#7596d7' }} />
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'right'}}>
            <div style={{position: 'relative', right: '0px', fontSize: '16px', top: '-60px', color: 'white'}}>
              <span style={{fontWeight: 'bold'}}>Total Quote Requests:</span> {rowCount}
            </div>
            <GridToolbarQuickFilter />
          </Grid>
        </Grid>
      </GridToolbarContainer>
  )
}


const CustomSearch = styled(CustomToolbar)`
  padding: 30px 0 50px;
  width: 100%;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialVisibilityModel = {
  company_name: true,
  created_by:false,
  requested_by: true,
  recipient_email: true,
  commodity:true,
  tradelane: true,
  trade_direction: true,
  creation_date: true,
  loading_location: true,
  delivery_location: true,
  quote_number: true,
  status: true,
  actions:true,
};

const SearchQuotes = () => {

  const initialCols = [
    {
      field: 'company_name',
      headerName: 'Company name',
      flex: 1,
    }, {
      field: 'created_by',
      headerName: 'Created by',
      flex: 1,
    },  {
      field: 'requested_by',
      headerName: 'Requested by',
      flex: 1,
    },  {
      field: 'request_email',
      headerName: 'Requestor email',
      flex: 1,
    },  {
      field: 'recipient_email',
      headerName: 'Recipient email',
      flex: 1,
    },
    {
      field: 'commodity',
      headerName: 'Commodity',
      flex: 1,
    }, {
      field: 'tradelane',
      headerName: 'Tradelane',
      flex: 1,
    }, {
      field: 'trade_direction',
      headerName: 'Trade Direction',
      flex: 1,
    }, {
      field: 'creation_date',
      headerName: 'Creation date',
      flex: 1,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dayjs(params.value).format('MM/DD/YYYY');
      },
    }, {
      field: 'loading_location',
      headerName: 'Loading location',
      flex: 1,
    }, {
      field: 'delivery_location',
      headerName: 'Delivery location',
      flex: 1,
    }, {
      field: 'quote_number',
      headerName: 'Quote number',
      flex: 1,
    }, {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              { params.value === 'delivered' &&
                  <CheckCircle fontSize='10px' sx={{marginRight: '8px'}}/>
              }
              { params.value === 'incomplete' &&
                  <SimCardAlert fontSize='10px' sx={{marginRight: '8px'}}/>
              }
              { params.value === 'in-contract' &&
                  <HourglassBottom fontSize='10px' sx={{marginRight: '8px'}}/>
              }
              <span style={{ marginLeft: params.value === 'complete' ? '22px' : '0'}}>{params.value}</span>
            </div>
        );
      }
    }, {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      align: 'right',
      getActions: (params) => {
        if(params.row.status === 'incomplete' || params.row.status === 'request') {
          return [
            <Tooltip placement="top" title="view quote">
              <GridActionsCellItem
                  icon={<VisibilityIcon sx={{color: '#7596d7'}}/>}
                  label="Edit"
                  className="grid-btn"
                  onClick={()=> handleViewClick(params.id)}
                  color="inherit"
              />
            </Tooltip>,
            <Tooltip placement="top" title="edit quote">
              <GridActionsCellItem
                  icon={<EditIcon sx={{color: '#7596d7'}}/>}
                  label="Edit"
                  className="grid-btn"
                  onClick={()=> handleEditClick(params.id)}
                  color="inherit"
              />
            </Tooltip>,
            <Tooltip placement="top" title="delete quote">
              <GridActionsCellItem
                  icon={<DeleteIcon sx={{color: '#7596d7'}} />}
                  label="Delete"
                  className="grid-btn-red"
                  onClick={()=> handleArchiveClick(params)}
                  color="inherit"
              />
            </Tooltip>,
          ];
        } else {
          return [
            <Tooltip placement="top" title="view, print and download pdf">
              <GridActionsCellItem
                  icon={<PictureAsPdfIcon sx={{color: '#7596d7'}} />}
                  label="Download PDF"
                  className="grid-btn"
                  onClick={()=>setModalOpen(true)}
                  color="inherit"
              />
            </Tooltip>,
            <Tooltip placement="top" title="view quote">
              <GridActionsCellItem
                  icon={<VisibilityIcon sx={{color: '#7596d7'}}/>}
                  label="Edit"
                  className="grid-btn"
                  onClick={()=> handleViewClick(params.id)}
                  color="inherit"
              />
            </Tooltip>,
            <Tooltip placement="top" title="edit quote">
              <GridActionsCellItem
                  icon={<EditIcon sx={{color: '#7596d7'}}/>}
                  label="Edit"
                  className="grid-btn"
                  onClick={()=> handleEditClick(params.id)}
                  color="inherit"
              />
            </Tooltip>,
            <Tooltip placement="top" title="clone quote">
              <GridActionsCellItem
                  icon={<FileCopyIcon sx={{color: '#7596d7'}}/>}
                  label="Clone"
                  className="grid-btn"
                  onClick={()=> handleCloneClick(params.id)}
                  color="inherit"
              />
            </Tooltip>,
            <Tooltip placement="top" title="delete quote">
              <GridActionsCellItem
                  icon={<DeleteIcon sx={{color: '#7596d7'}} />}
                  label="Delete"
                  className="grid-btn-red"
                  onClick={()=> handleArchiveClick(params)}
                  color="inherit"
              />
            </Tooltip>,
          ];
        }

      },
    },
  ];

  const showHideCols = () => {
    if (localStorage.getItem("quoteSearchGridCols")) {
      const saveCols = JSON.parse(localStorage.getItem("quoteSearchGridCols"));
      const tempCols = [...initialCols];
      for(let i in saveCols) {
        const currCol =  tempCols.filter(col => col.field === saveCols[i].field)[0];
        currCol.hide = saveCols[i].hide;
      }

      return tempCols
    } else {
      return initialCols
    }
  }


  const [quoteGridData, setQuoteGridData] = React.useState(false);
  const [gridCols, setGridCols] = React.useState(initialCols);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState( JSON.parse(localStorage.getItem("quoteSearchGridColumnVisibilityModel")) || initialVisibilityModel);
  const [selectedRowParams, setSelectedRowParams] = React.useState();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [quoteModalOpen, setQuoteModalOpen] = React.useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = React.useState(false);
  const [quoteJSONData, setQuoteJSONData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);

  const navigate = useNavigate();


  const getAllQuotes = () => {
    quoteApi.post({postType: 'getGridData', data: {}}).then((d) => {
      const updatedGridData = d.results;
      setQuoteGridData(updatedGridData);
      setShowSpinner(false);
    });
  };

  const handleViewClick = (id) => {
    setShowSpinner(true);

    quoteApi.post({postType: 'getQuote', data: {recordID: id}}).then((d) => {
      const res = d?.results[0];
      //console.log(" THE RESULTS  ", res)

      setQuoteJSONData(JSON.parse(res.quote_data_grid));
      // // if this is a clone, set general quote params to edit mode
      setQuoteModalOpen(true);
      setShowSpinner(false);
    });

  };

  const handleEditClick = (id) => {
    navigate({
      pathname: "/quoteTool/newQuote",
      search: createSearchParams({
        view: 'edit',
        id
      }).toString()
    });
  };

  const handleCloneClick = (id) => {
    navigate({
      pathname: "/quoteTool/newQuote",
      search: createSearchParams({
        view: 'clone',
        id
      }).toString()
    });
  };

  const handleArchiveClick = (params) => {
    setSelectedRowParams(params);
    setWarningDialogOpen(true);
  };

  const handleNewQuoteClick = (params) => {
    navigate({
      pathname: "/quoteTool/newQuote"
    });
  };

  const archiveQuote = (id) => {
    setWarningDialogOpen(false);
    setShowSpinner(true);
    quoteApi.post({postType: 'archiveQuote',  data: {recordID: id}}).then((d) => {
      // handle error
      getAllQuotes();
    });
  };


  function getRowId(row) {
    return row.quote_id;
  }

  useEffect(()=>{
    getAllQuotes();
  }, []);

  if(showSpinner) {
    return (<Box sx={{position: 'absolute', left: '50%'}}>
      <CircularProgress/>
    </Box>)
  } else {
    return (
        <Box sx={{width: '100%'}}>
          <div style={{height: 400, width: '100%'}}>
            <Grid item xs={12}>
              <Card
                  sx={{
                    border: '1px solid #ababab', boxShadow: 'none', borderRadius: 0,
                  }}
              >
                <div className='quote-form-header-area'>
                  <Grid container>
                    <Grid item xs={6} style={{display: 'inline-flex', alignItems: 'center'}}>
                      <SearchIcon sx={{margin: '-5px 13px 0 0'}}/>Search Quotes
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'right', paddingRight: '15px'}}>
                    </Grid>
                  </Grid>
                </div>
                <Divider sx={{backgroundColor: '#ccc'}}/>
                <CardContent sx={{padding: '0px !important'}}>
                  <DataGrid
                      disableColumnFilter
                      disableDensitySelector
                      getRowId={getRowId}
                      rows={quoteGridData}
                      editMode="row"
                      columns={gridCols}
                      slots={{ toolbar: CustomSearch,  }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          printOptions: { disableToolbarButton: true },
                          csvOptions: { disableToolbarButton: true },
                        },
                        columnsPanel: {
                          disableHideAllButton: true,
                          disableShowAllButton: true,
                        },
                      }}
                      columnVisibilityModel={columnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel) => {
                        setColumnVisibilityModel(newModel);
                        localStorage.setItem("quoteSearchGridColumnVisibilityModel", JSON.stringify(newModel));
                      }

                      }
                      getCellClassName={(params) => {
                        if (params.field === 'status' && params.value === 'incomplete') {
                          return 'incomplete';
                        }
                        if (params.field === 'status' && params.value === 'delivered') {
                          return 'delivered';
                        }
                        if (params.field === 'status' && params.value === 'in-contract') {
                          return 'incontract';
                        }
                      }}
                      sx={{
                        '& .incomplete': {
                          color: '#edaa00',
                        },
                        '& .delivered': {
                          color: '#277b18',
                        },
                        '& .incontract': {
                          color: '#32A3D7FF',
                        },
                      }}
                  />
                  <Divider sx={{backgroundColor: '#ccc'}}/>
                  <div className='quote-form-action-area'>
                    <button onClick={handleNewQuoteClick} className='form-save-btn'>
                      New Quote
                    </button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </div>
          <Dialog
              fullScreen
              open={quoteModalOpen}
              onClose={()=> setQuoteModalOpen(false)}
              TransitionComponent={Transition}
          >
            <Box sx={{width: '100%', textAlign: 'right', paddingRight: '10px'}}>
              <IconButton
                  edge="end"
                  color="inherit"
                  onClick={()=> setQuoteModalOpen(false)}
                  aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <pre>{JSON.stringify(quoteJSONData, null, 2)}</pre>
          </Dialog>
          <Dialog
              fullScreen
              open={modalOpen}
              onClose={()=> setModalOpen(false)}
              TransitionComponent={Transition}
          >
            <Box sx={{width: '100%', textAlign: 'right', paddingRight: '10px'}}>
              <IconButton
                  edge="end"
                  color="inherit"
                  onClick={()=> setModalOpen(false)}
                  aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <PDFViewer width='100%' height='100%'>
              <QuotePDF />
            </PDFViewer>
          </Dialog>
          <Dialog
              open={warningDialogOpen}
              onClose={()=> setWarningDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Warning:  Deleting Quote"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You are about to delete a quote for { selectedRowParams?.row.company_name }, created on { dayjs(selectedRowParams?.row.creation_date).format('MM-DD-YYYY') }.
                Do you wish to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=> archiveQuote(selectedRowParams.id)}>delete</Button>
              <Button onClick={()=> setWarningDialogOpen(false)} autoFocus>
                cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Box>);
  }
};

export default SearchQuotes;
