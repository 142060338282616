import { useContext } from "react";
import { UserContext } from "../contexts/userContext";

const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within a Settings Provider')
    }
    return context;
}

export default useUser;

